import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import { ReactComponent as LocationIcon } from "../../../Assets/location.svg"
import { ReactComponent as CalendarIcon } from "../../../Assets/calendar.svg"
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import EventCards from "./Cards"

interface Props {
    content?: any
}

/**
 * Event Details Component
 * @param content - Content from the CMS.
 */
const EventDetails = ({content}: Props) => {
    return (<>
        <div className={`layoutSpacing w-100 py-80 bg-white`}>
            <div className="layout">
                <div className="pb-80">
                    <TextButtonWithIcon variant="dark" name="Back to all events" underline={true} link="/" iconPosition="start" arrowDirection="left" classes="!text-black" />
                </div>
                <div className="flex flex-wrap gap-32">
                    <div className={`w-100 xl:w-75`}>
                        <Headings variant="H2" content="Planning Your Retirement" classes="text-purple-100" />
                        <Paragraph content="Assisting members in better preparing for their retirement. Suitable for members who are considering retirement within the next few years. Topics covered include: Super basics, how to enjoy a regular income after you retire, making your super last longer, protecting your loved ones with insurance through super, contribution splitting, salary sacrifice, Centrelink eligibility and entitlements, and much more!" classes="w-100 xl:w-75" />
                    </div>
                    <div className={`grow shrink-0`}>
                        <div className="flex flex-col gap-32 grow">
                            <div className="flex flex-col items-start gap-8">
                                <div className="flex items-center gap-8">
                                    <LocationIcon />
                                    <Paragraph content="Fund type" classes="!text-sm"/>
                                </div>
                                <div className="flex pl-24">
                                    <Paragraph content="Emergency services"/>
                                </div>
                            </div>
                            <div className="flex flex-col items-start gap-8">
                                <div className="flex items-center gap-8">
                                <CalendarIcon />
                                    <Paragraph content="Presenter" classes="!text-sm"/>
                                </div>
                                <div className="flex pl-24">
                                    <Paragraph content="Member Education Team"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`w-100 xl:w-75 rounded-2xl overflow-hidden hover:border-purple-100 p-24 md:p-32 bg-purple-10`}>
                        <div className="flex flex-col gap-32 grow">
                            <div className="flex flex-col items-start gap-8">
                                <div className="flex items-center gap-8">
                                    <LocationIcon />
                                    <Paragraph content="Location" classes="!text-sm"/>
                                </div>
                                <div className="flex pl-24">
                                    <Paragraph content="Rydges Geelong<br/>Cnr Gheringhap St & Myers St<br/>Geelong, VIC, Australia"/>
                                </div>
                            </div>
                            <div className="flex flex-col items-start gap-8">
                                <div className="flex items-center gap-8">
                                <CalendarIcon />
                                    <Paragraph content="Date" classes="!text-sm"/>
                                </div>
                                <div className="flex pl-24">
                                    <Paragraph content="Tuesday, 27 September 10:00 AM - 12:00 PM"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-32">
                    <PrimaryButtonWithIcon name="Register" variant="dark" />
                </div>
            </div>
        </div>
        <EventCards cardBackground="white" border={true} columnLayout="3" />
    </>)
}

export default EventDetails