import React from 'react';

interface InputProps {
    label?: string;
    name?: string;
    placeholder?: string;
    className?: string;
    error?: boolean;
    errorMessage?: string;
    helpText?:string;
    isDisabled?: boolean;
    variant?: 'text' | 'number' | 'date' | 'email';
    required?: boolean
    pattern?: string
    onChange?: (name: string, value: string | number) => void;
}

const InputComp = ( props: InputProps) => {
    const { label, name, placeholder, className, error, errorMessage, helpText, isDisabled = false,  variant = 'text', required, pattern, onChange } = props;
    return (
        <div className={`${className ? className : ''} py-16`}>
            {label && <label className="block text-neutral-100 text-md mb-8">{label}</label>}
            <input
                data-testid="input"
                type={variant}
                pattern={pattern}
                name={name}
                className={`border rounded-md md:w-100 p-16 text-neutral-90
                    hover:outline-none 
                    hover:border-purple-100
                    hover:text-neutral-100 
                    active:outline-none 
                    active:border-purple-100
                    active:text-neutral-100
                    mb-8 ${error ? 'border-danger-100 invalid' : 'border-neutral-50'}`}
                placeholder={placeholder}
                disabled={isDisabled}
                required={required}
                onChange={(e) => onChange(e.target.name, e.target.value)}
            />
            {error && errorMessage && <small className={`block text-danger-100 text-base mb-8`}>{errorMessage}</small>}
            {helpText && <small className="block text-neutral-100 text-base">{helpText}</small>}
        </div>
    );
};

export default InputComp;
