import * as React from "react"
import PrimaryButtonWithIcon from "./Primary/ButtonWithIcon"
import SecondaryButtonWithIcon from "./Secondary/ButtonWithIcon"
import OutlineButtonWithIcon from "./Outline/ButtonWithIcon"
import TextButtonWithIcon from "./Text/ButtonWithIcon"
import TealButtonWithIcon from "./Teal/ButtonWithIcon"
import DownloadButtonWithIcon from "./Download/ButtonWithIcon"
import CardButton from "./Card/Button"

interface Props {
  item: any,
  columns?: string
}

/**
 * Render Button Component
 * @param item - Objects of the button.
 */

const RenderButton = ({ item }: Props) => {

  let buttonType = item?.fields?.ctaButtonVariety
  buttonType = buttonType && buttonType.toLowerCase()

  return (<>
    <div className={`flex flex-col flex-wrap gap-16 `}>
            {buttonType === "primarylight" && 
                <div key={item?.sys?.id}>
                    <PrimaryButtonWithIcon name={item?.fields?.ctaText} variant="light" link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>
            }
            {buttonType === "primarydark" && 
                <div key={item?.sys?.id}>
                    <PrimaryButtonWithIcon name={item?.fields?.ctaText} variant="dark" link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>  
            }
            {buttonType === "secondary" && 
                <div key={item?.sys?.id}>
                    <SecondaryButtonWithIcon name={item?.fields?.ctaText} link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>  
            }
            {buttonType === "outlinelight" && 
                <div key={item?.sys?.id}>
                    <OutlineButtonWithIcon name={item?.fields?.ctaText} variant="light" link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>  
            }
            {buttonType === "outlinedark" && 
                <div key={item?.sys?.id}>
                    <OutlineButtonWithIcon name={item?.fields?.ctaText} variant="dark" link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>  
            }
            {buttonType === "textlight" && 
                <div key={item?.sys?.id}>
                    <TextButtonWithIcon name={item?.fields?.ctaText} variant="light" link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>  
            }
            {buttonType === "textdark" && 
                <div key={item?.sys?.id}>
                    <TextButtonWithIcon name={item?.fields?.ctaText} variant="dark" link={item?.fields?.buttonLinkExternal} classes="mb-16"/>
                </div>  
            }
            {buttonType === "teal" && 
                <div key={item?.sys?.id}>
                    <TealButtonWithIcon name={item?.fields?.ctaText} link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>
            }
            {buttonType === "downloadbutton" && 
                <div key={item?.sys?.id}>
                    <DownloadButtonWithIcon variant="dark" name={item?.fields?.ctaText} link={item?.fields?.buttonLinkExternal} classes="mb-16" />
                </div>
            }
        </div>
    </>
  )
}

const RenderCardButton = ({ item, columns }: Props) => {
  return (
    <div className="flex flex-wrap gap-16">
        {item.map((button) => (
            <div key={button?.sys?.id} className={`w-full md:w-[48.5%] ${columns} md:max-w-[50%]`}>
                <CardButton variant="stacked" name={button?.fields?.ctaText} link={button?.fields?.buttonLinkExternal} />
            </div>
        ))}
    </div>
    )
}


export { RenderCardButton, RenderButton }
