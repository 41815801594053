import * as React from "react"
import Headings from "../../Generic/Heading"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"
import './style.css'

interface Props {
    content?: any
}
/**
 * Quick Links Component
 */
const QuickLinksBlock = ({content}: Props) => {
    const { isMobile } = useDeviceDetector()

    const toggleQuickLinks = (e: any) => {
        e.stopPropagation()
        if(isMobile) {
            const parentEle = e.target.closest('.container')
            parentEle.classList.toggle('active')
        }
    }

    return (<>
        <div className="layoutSpacing">
            <div className="w-100 pt-48 pb-24 layout">
                <div className={`flex flex-wrap quicklinks ${isMobile ? 'mobile' : ''}`}>
                    {content?.features?.map((item, index) =>
                        <div key={`${item?.fields?.headline}-${index}`} className="container flex flex-col basis-full md:basis-6/12 xl:basis-3/12 pb-24 md:pr-24">
                            <div className="title flex flex-grow justify-between items-cs pb-12 mb-16 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleQuickLinks(e)}}>
                                <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12" />
                                {isMobile && (<div key={`arrow-icon-${index}`}>
                                    <ArrowUpIcon />
                                    <ArrowDownIcon />
                                    </div>)
                                }
                            </div>
                            <div className={`list flex flex-col items-start w-100 mb-16`}>
                                {item?.fields?.linksgroup?.map((links) =>
                                    <div key={`${links?.fields?.linkName}-${index}`}>
                                        <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={false} classes="mb-8" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export default QuickLinksBlock