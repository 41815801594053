import * as React from "react"
import {ReactComponent as PlayIcon} from '../../../Assets/play.svg'

interface Props {
    videoUrl: string
}

/**
 * Video Component
 * @param videoUrl - URL of the video.
 */

const Video = ({videoUrl}: Props) => {

    const playVideo = (e) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.playIconWrapper')
        parentEle.nextElementSibling.play()
        parentEle.classList.add('z-0')
    }
    const handleVideo = (e) => {
        e.stopPropagation()
        if(!e.target.paused) {
            e.target.pause()
            const playWrapper = e.target.previousElementSibling
            playWrapper.classList.remove('z-0')
        }
    }

    return (<>
        <div className="w-full py-48 layout">
            <div className={'relative w-full aspect-video'}>
                <div className="playIconWrapper flex justify-center items-center absolute z-10 w-full h-full bg-black bg-opacity-50">
                    <div className="w-[15%] max-w-[120px] cursor-pointer" onClick={(e) => {playVideo(e)}}>
                        <PlayIcon/>
                    </div>
                </div>
                <video className="absolute top-0 left-0 w-full h-full" width="100%" height="auto" onClick={(e) => {handleVideo(e)}}>
                    <source src={videoUrl} type="video/mp4" />
                    <iframe src={videoUrl} title="mp4 video" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
        </>
    )
    
}

export default Video