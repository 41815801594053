import * as React from "react";
import TextButton from "../../../Generic/Button/Text/Button";
import Paragraph from "../../../Generic/Paragraph";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
interface Props {
  content?: any;
}

const mainMenu = ({ content }: Props) => {

  return (
    <div className="layoutSpacing bg-white hidden xl:flex w-full border border-b-neutral-50">
      <div className="w-full layout">
        <ul className="megaMenu">
          {content?.fields?.navigationSubSection?.fields?.menus.map(
            
            (menuitem, index) => {

              const submenuList =
                menuitem?.fields?.menusDropdown &&
                menuitem?.fields?.menusDropdown.slice(
                  0,
                  menuitem?.fields?.menusDropdown.length - 1
                );
              const lastMenuItem =
                menuitem?.fields?.menusDropdown &&
                menuitem?.fields?.menusDropdown[
                  menuitem?.fields?.menusDropdown.length - 1
                ];
              const columnCount = Math.min(
                submenuList.length,
                submenuList.length > 4 ? 3 : 2
              );
              const itemsPerColumn = Math.ceil(submenuList.length / columnCount)
              const remainder = submenuList.length % columnCount;
              const columns =  []

              let currentIndex = 0
              for (let i = 1; i <= columnCount; i++) {
                const extraItem = i < remainder ? 1 : 0;
                const end = currentIndex + itemsPerColumn + extraItem;
                columns.push((submenuList.slice(currentIndex, end)))
                currentIndex = end
              }
              return (
                <li key={`"menu-"${index}`} className="hoverable py-32 float-left mr-32 hover:border-b-2 hover:border-purple-100 active:border-b-2 active:border-purple-100 ">
                  <TextButton
                    variant="dark"
                    name={menuitem?.fields?.groupName}
                    classes=" text-neutral-100 "
                    link={menuitem?.fields?.externelLink}
                  />

                  <div className="mega-menu mb-16 shadow-xl bg-neutral-10 mx-auto w-full flex flex-wrap flex-row  justify-between ">
                    <div className="w-75 py-48 pr-48 pl-64 flex ">
                      <div className="flex w-100 gap-32">
                        {columns.map((column, columnIndex) => (
                          <div key={columnIndex} className={`flex-1 pr-32`}>
                            {column.map((item, itemIndex) => (
                              <ul
                                key={itemIndex}
                                className="mb-11 pb-16 border-b border-b-neutral-50 last:border-none last:mb-0 flex flex-col "
                              >
                                <li className="justify-left mb-16">
                                  <TextButton
                                    variant="dark"
                                    name={item?.fields?.menuName}
                                    link={item?.fields?.externelLink}
                                    classes="justify-left hover:underline"
                                  />
                                </li>

                                <li className="justify-left mb-16">
                                  <Paragraph
                                    content={documentToHtmlString(
                                      item?.fields?.menuDecription
                                    )}
                                    classes="flex items-start text-base textlink"
                                  />
                                </li>
                              </ul>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="w-25 py-48 pr-48 pl-48 list flex flex-col items-start  bg-neutral-20">
                      <ul className="w-100  flex flex-col ">
                        <li className="justify-left mb-24">
                          <TextButton
                            variant="dark"
                            name={lastMenuItem?.fields?.menuName}
                            link={lastMenuItem?.fields?.externelLink}
                            fontColor="text-black"
                            classes="justify-left hover:underline font-normal"
                          />
                        </li>

                        <li className="justify-left mb-16">
                          <Paragraph
                            content={documentToHtmlString(
                              lastMenuItem?.fields?.menuDecription
                            )}
                            classes="flex items-start text-base mb-16 subMenulink"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  );
};
export default mainMenu;
