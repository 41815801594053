import * as React from "react";
import { useEffect, useRef, useState } from "react";
import OutlineButtonSearchIcon from "../../../Generic/Button/Outline/SearchIconButton";
import OutlineButton from "../../../Generic/Button/Outline/Button";
import SecondaryButton from "../../../Generic/Button/Secondary/Button";
import TextButton from "../../../Generic/Button/Text/Button";
import { ReactComponent as SearchIco } from "../../../../Assets/search.svg";
interface Props {
  content?: any;
}

const DesktopMenu = ({ content }: Props) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);

  const toggleSearch = () => {
    setIsSearchOpen((prevState) => !prevState);
  };

  const handelClickOutside = (event) => {
    if(searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }
}

useEffect(()=> {
    document.addEventListener('mousedown', handelClickOutside);

    return () => {
        document.removeEventListener('mousedown', handelClickOutside);
    }
});

  return (
    <div className="md:flex flex-row w-full">
      <div className="flex-1 pl-6 justify-between relative">
        <ul className="headerMenu py-5 ml-12">
          {content?.fields?.navigationSection?.fields?.menus.map(
            (item, index) => (
              <li
                className="m-0 px-16 float-left text-18 font-semibold last:border-l"
                key={`${item?.sys?.id}-${index}`}
              >
                <TextButton
                  variant="light"
                  name={item?.fields?.groupName}
                  classes="text-neutral-10 hover:underline"
                  link={item?.fields?.externelLink}
                />
              </li>
            )
          )}
        </ul>
        <div>
        {isSearchOpen && (
          <div  ref={searchRef} className="left-0 right-0 ml-14 absolute top-0">
            <div className="relative w-full flex justify-center  ">
              <input
                type="text"
                className="w-full bg-neutral-10 p-16 pr-50 rounded-lg border border-neutral-50 focus:outline-none text-neutral-90 text-lg focus:border-violet-300 transition-colors duration-300"
                placeholder="Search..."
              />
              <div className="absolute inset-y-0 right-16 pl-3 flex items-center pointer-events-none">
                <SearchIco />
              </div>
            </div>
          </div>
        )}
        </div>
      </div>
      <div className="flex flex-row-reverse items-center">
        <SecondaryButton
          name={
            content?.fields?.navigationSection?.fields?.login?.fields?.ctaText
          }
          link={
            content?.fields?.navigationSection?.fields?.login?.fields
              ?.buttonLinkExternal
          }
          classes="mr-12"
        />
        <OutlineButton
          variant="light"
          name={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.ctaText
          }
          buttonType="button"
          link={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.buttonLinkExternal
          }
          classes="mr-12"
        />
        {!isSearchOpen && (
          <OutlineButtonSearchIcon
            variant="light"
            buttonType="button"
            classes="mr-12"
            onClick={() => toggleSearch()}
          />
        )}
        {isSearchOpen && (
          <OutlineButtonSearchIcon
            variant="dark"
            buttonType="button"
            classes="mr-12"
            onClick={() => toggleSearch()}
          />
        )}
      </div>
    </div>
  );
};
export default DesktopMenu;
