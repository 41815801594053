import { useEffect, useState } from "react"
import axios, { AxiosRequestConfig, } from 'axios'
import resolveResponse from "contentful-resolve-response"

const spaceId = "sv3l1bj7dxrn" // "#{spaceId}#" //process.env.REACT_APP_SPACE_ID 
const environment = "marketing-studio"

const deliveryAPI = 'https://cdn.contentful.com'
const deliveryToken = "cFn9GLLNALR2_MJ_P_KWHaNbjPsmzLvK2jqNJUR2Ly8" //process.env.REACT_APP_ACCESS_TOKEN

const previewAPI = 'https://preview.contentful.com'
const previewToken = "L3QVeCPMDYkLXWz_GA7znae9N0jNpuf4J4djRObWfXg"

const contentAPI = 'https://api.contentful.com'
const contentToken = ""

/**
 * Content API to get based on entries and content type
 * @param apiType - Type of the API. Expected Values : 'entries' | 'contentType'.
 * @param contentTypeId - Content Type ID. Expected Values : 'entries' | 'contentType'.
 * @param slug (Optional) - Slug of the page. Required if apiTYpe is entries.
 * @param preview (Optional) - Enables Live preview. Expected Values : 'true' | 'false'.
 */

const useContentAPI = (apiType: string, contentTypeId: string, slug: string, preview: boolean) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const url = `${preview ? previewAPI : deliveryAPI}/spaces/${spaceId}/environments/${environment}`

    const entriesAPI = async () => {

        try {
            const options =
            {
                params: {
                    content_type: `${contentTypeId}`,
                    'fields.slug': `${slug}`,
                    select: 'fields,sys.id',
                    include: 5
                },
                headers: {
                    Authorization: `Bearer ${preview ? previewToken : deliveryToken}`
                }
            }
            const response = await axios.get(`${url}/entries`, {...options})

            const resolver = {items: response.data?.items, includes: response.data?.includes}
            const resolvedResponse = resolveResponse(resolver)
            
            setData(resolvedResponse[0])
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    const contentTypeAPI = async () => {

        try {
            const options = {
                params: {
                    select: 'fields,sys.id',
                    include: 5
                },
                headers: {
                    Authorization: `Bearer ${preview ? previewToken : deliveryToken}`
                }
            }

            const response = await axios.get(`${url}/content_types/${contentTypeId}`, {...options})
            
            setData(response)
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    useEffect(() => {

        if(apiType === 'entries') {
            entriesAPI()
        } else {
            contentTypeAPI()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { data, isLoading, error }
}

const AddEntryAPI = (contentTypeId: string, payload: any, options?: AxiosRequestConfig) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const fetchData = async () => {

        try {
            const response = await axios.post(`${contentAPI}/spaces/${spaceId}/environments/${environment}/entries/`, payload, {
                headers: {
                    Authorization: `Bearer ${contentToken}`,
                    "Content-Type": "application/vnd.contentful.management.v1+json",
                    "X-Contentful-Content-Type": `${contentTypeId}`
                }
            })
            
            setData(response)
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { data, isLoading, error }
}

export { useContentAPI, AddEntryAPI }