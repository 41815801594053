import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

interface Props {
    content: any
    fullWidth?: boolean
    border?: boolean
    classes?: string
}

/**
 * Disclaimer Component
 * @param content - Content from the CMS.
 * @param fullWidth (optional) - Full width. Expected values: true | false, Default: true
 * @param border (optional) - Top border for the component. Expected values: true | false, Default:  false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */
const DisclaimerBlock = ({content, fullWidth = false, border, classes}: Props) => {

    return (<>
        {content?.fields &&
            <div className={`${fullWidth ?  '' : 'layoutSpacing'} w-100 ${border ? 'border-neutral-50 border-t' : ''}`}>
                <div className={`disclaimer w-100 ${border ? 'py-80' : ''} ${fullWidth ?  '' : 'layout'}`}>
                    {content?.fields?.disclaimer?.content?.map((item, index) => 
                        <div key={`${content?.sys?.id}-${index}`}>
                            <Paragraph content={documentToHtmlString(item)} classes={`${classes ? classes : 'text-neutral-90'} ${content?.fields?.disclaimer?.content.length > 0 && 'pb-16'}`} />
                        </div>
                    )}
                </div>
            </div>
        }
        </>
    )
}

export default DisclaimerBlock