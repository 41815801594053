import * as React from "react"
import { useState } from "react"
import TabSelector from "../../Generic/TabSelector"
import EventCards from "./Cards"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
  content?: any
  background?: string
  cardBackground?: string
  columnLayout?: string
  border?: boolean
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const Events = ({ content, background = 'white', cardBackground = 'white', columnLayout = '2', border = false }: Props) => {
  const [selectedTabs, setSelectedTabs] = useState([])
  const bgColor = background === 'purple' ? 'bg-purple-10' : background === 'orange' ? 'bg-orange-10' : background === 'teal' ? 'bg-teal-10' : 'bg-white'

  return (<>
      {(content?.headline || content?.description) && 
        <div className={`${bgColor} layoutSpacing`}>
          <div className="w-full layout">
            <div className="w-full md:w-60">
              {content?.headline &&
                <Headings
                  content={content?.headline}
                  variant="H2"
                  classes="text-purple-100"
                />
              }
              {content?.description &&
                <Paragraph
                  content={documentToHtmlString(content?.description)}
                  classes="pb-16"
                />
              }
            </div>
          </div>
        </div>
      }
      <TabSelector content={content?.features} selectedTabs={selectedTabs} setSelectedTabs={setSelectedTabs} />
      <EventCards border={true} content={content?.features} selectedTabs={selectedTabs} />
    </>
  )
}

export default Events
