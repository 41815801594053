import * as React from "react"
import { useEffect } from "react"
import Paragraph from "../Paragraph"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import './style.css'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"

interface Props {
    content?: any
    selectedTabs?: any
    setSelectedTabs?: any
}

/**
 * Tabs Selector Component
 * @param content - Content from the CMS.
 */
const TabSelector = ({content, selectedTabs, setSelectedTabs}: Props) => {
    const { isMobile } = useDeviceDetector()

    const toggleTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const element = e.target.closest('.tabHeading').querySelectorAll('.tabs')
        for(var i=0; i<element.length; i++) {
            element[i].classList.remove('active')
        }
        parentEle.classList.add('active')
        const selected = {
            tabGroup: parentEle.getAttribute('data-group'),
            tabItem: parentEle.getAttribute('data-item')
        }
        const findIndex = selectedTabs?.findIndex((item) => item?.tabGroup === selected?.tabGroup)
        findIndex >= 0 && selectedTabs.splice(findIndex, 1)
        setSelectedTabs([...selectedTabs, selected])
    }

    const toggleMobileTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.mobileSelectedTab')
        parentEle.classList.toggle('active')
        parentEle.nextElementSibling.classList.toggle('active')
    }

    const selectTab = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const tabSelector = e.target.closest('.mobileTab')
        const mobileSelectedTab = tabSelector.querySelector('.mobileSelectedTab')
        const text = parentEle.children[0].textContent
        mobileSelectedTab.querySelector('.selected p').innerHTML = text
        mobileSelectedTab.classList.toggle('active')
        mobileSelectedTab.nextElementSibling.classList.toggle('active')
        const selected = {
            tabGroup: parentEle.getAttribute('data-group'),
            tabItem: parentEle.getAttribute('data-item')
        }
        const findIndex = selectedTabs?.findIndex((item) => item?.tabGroup === selected?.tabGroup)
        findIndex >= 0 && selectedTabs.splice(findIndex, 1)
        setSelectedTabs([...selectedTabs, selected])
    }

    useEffect(() => {
        let selectedData = []

        content?.forEach((group) => {
            const tabGroup = group?.fields?.tabsGroup
            if (tabGroup && tabGroup[0]) {
                const items = {
                    tabGroup: group?.fields?.headline,
                    tabItem: tabGroup[0]?.fields?.tabTitle
                }
                selectedData.push(items)
            }
        })
        setSelectedTabs([...selectedTabs, ...selectedData])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])

    return (
        <div className={`${content[0]?.fields?.carousel ? 'pt-32' : 'border-b border-neutral-50 py-32'}`}>
            <div className="layoutSpacing">
                <div className={`flex flex-wrap gap-24 w-full layout tabSelector`}>
                    {content?.map((item, index) => <>
                        {item?.fields?.tabsGroup &&
                            <div key={`${item?.fields?.headline}-${index}`} className={`${isMobile ? 'min-w-full' : 'min-w-[300px]'}`}>
                                <Paragraph content={item?.fields?.headline} classes="pb-8" />
                                {!isMobile && !item?.fields?.booleanDropdown ?
                                    <div className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}>
                                        <div className={`tabHeading flex`}>
                                            {item?.fields?.tabsGroup?.map((tab, index) =>
                                                <div key={`${tab?.fields?.tabTitle}-${index}`} data-group={item?.fields?.headline} data-item={tab?.fields?.tabTitle} className={`tabs px-24 py-16 cursor-pointer ${index === 0 ? 'rounded-tl-lg rounded-bl-lg active' : index === content?.fields?.tabsGroup?.length - 1 ? 'rounded-tr-lg rounded-br-lg' : ''}`} onClick={(e) => {toggleTabs(e)}}>
                                                    <Paragraph content={tab?.fields?.tabTitle} />
                                                </div>   
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className={`relative mobileTab`}>
                                        <div className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`} onClick={(e) => {toggleMobileTabs(e)}}>
                                            <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                                <Paragraph content={item?.fields?.tabsGroup[0]?.fields?.tabTitle} />
                                            </div>
                                            <div className="">
                                                <ArrowUpIcon />
                                                <ArrowDownIcon />
                                            </div>
                                        </div>
                                        
                                        <div className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl`}>
                                            {item?.fields?.tabsGroup?.map((group, index) =>
                                                <div key={`${group?.fields?.tabTitle}-${index}`} data-group={group?.fields?.headline} data-item={group?.fields?.tabTitle} className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                                    <Paragraph content={group?.fields?.tabTitle} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TabSelector