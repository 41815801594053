import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import calculateIcon from '../../../Assets/calculate.svg'
import FeatureImage from '../../../Assets/feature.png'
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import Image from "../../Generic/Image"

interface Props {
    content?: any
}

/**
 * Listing Component
 * @param content - Content from the CMS.
 */
const Listing = ({content}: Props) => {
    return (<>
        <div className="layoutSpacing">
            <div className="w-100 pb-80 layout">
                <Headings content="Retirement and savings" variant="H2" classes="text-purple-100" />
                <div className={`flex flex-col lg:flex-row items-center bg-white border-b border-neutral-50 py-32 gap-40`}>
                    <div className="flex justify-start lg:justify-center items-center w-full lg:w-[200px]">
                        <Image imageUrl={FeatureImage} altText="Listing Image" />
                    </div>
                    <div className="flex-1">
                        <div className="">
                            <Paragraph content="Tag" classes="!text-base text-neutral-90 pb-16" />
                            <Paragraph content="18 Feb 2024" classes="!text-base text-neutral-90 pb-24" />
                            <Headings content="Super retirement calculator" variant="H4" classes="text-black !pb-16" />
                            <Paragraph content="How much super do you need to retire?<br/><br/>
    Are you in a good position now or do you need to make some changes? Use this calculator to see how much super you can expect based on your current circumstances and how long it will last into your retirement. Watch this short video for an easy to digest explanation of how to estimate your future super." classes="text-black pb-32" />
                            <TextButtonWithIcon name="Open the super retirement calculator" variant="dark" link="/"/>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col lg:flex-row items-center bg-white border-b border-neutral-50 py-32 gap-40`}>
                    <div className="flex justify-start lg:justify-center w-full lg:w-[200px]">
                        <Image imageUrl={calculateIcon} altText="Listing Image" />
                    </div>
                    <div className="flex-1">
                        <div className="">
                            <Paragraph content="Tag" classes="!text-base text-neutral-90 pb-16" />
                            <Paragraph content="18 Feb 2024" classes="!text-base text-neutral-90 pb-24" />
                            <Headings content="Super retirement calculator" variant="H4" classes="text-black !pb-16" />
                            <Paragraph content="How much super do you need to retire?<br/><br/>
    Are you in a good position now or do you need to make some changes? Use this calculator to see how much super you can expect based on your current circumstances and how long it will last into your retirement. Watch this short video for an easy to digest explanation of how to estimate your future super." classes="text-black pb-32" />
                            <TextButtonWithIcon name="Open the super retirement calculator" variant="dark" link="/"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Listing