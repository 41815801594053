import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import DownloadButtonWithIcon from "../../Generic/Button/Download/ButtonWithIcon"
import Image from "../../Generic/Image"

interface Props {
    content?: any
}

/**
 * Forms and Transactions Component
 * @param content - Content from the CMS.
 */
const FormsAndTransactions = ({content}: Props) => {
    return (<>
        <div className="layoutSpacing">
            <div className="w-100 pb-80 layout">
                <div className="border border-neutral-50 rounded-2xl overflow-hidden">
                    <Headings variant="H4" content={content?.headline} classes="bg-purple-100 text-white !p-32" />
                    {content?.features?.map((item) =>
                        <div key={item?.sys?.id} className="flex flex-col xl:flex-row justify-between items-start xl:items-center px-32 py-24 gap-24 border-b border-bg-neutral-50">
                            <div className="flex flex-col xl:flex-row items-start xl:items-center gap-24 xl:gap-32">
                                <Image imageUrl={item?.fields?.cardImage?.fields?.file?.url} altText={item?.fields?.headingName} height="50" />
                                <div>
                                    <Paragraph content={item?.fields?.headingName} classes="font-semibold mb-8" />
                                    <Paragraph content={item?.fields?.description}  classes="!text-base" />
                                </div>
                            </div>
                            <div>
                                <DownloadButtonWithIcon name={item?.fields?.downloadButton?.fields?.ctaText} link={item?.fields?.downloadButton?.fields?.buttonLinkExternal} variant="dark" underline={true} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export default FormsAndTransactions