import * as React from "react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ContainerSection from "../Generic/ContainerSection"
import { TrackGAPageView } from "../GoogleAnalytics"
import StyleGuide from "./StyleGuides"
import DisclaimerBlock from "./Disclaimer"
import QuickLinksBlock from "./QuickLinks"
import { useContentAPI } from "../../Hooks/useAPI"
import HeroBanner from "./HeroBanner"
import Header from "./Header"
import Footer from "./Footer"
import Card from "./Card"
import { Helmet, HelmetProvider } from "react-helmet-async"
import PageBanner from "./PageBanner"
import TextBlock from "./TextBlock"
import FormsAndTransactions from "./FormsAndTransaction"
import BannerNotification from "./BannerNotification"
import Callout from "./Callout"
import SocialMedia from "./SocialMedia"
import FeatureBlock from "./Feature"
import ContactUsForm from "./ContactUsForm"
import Events from "./Events"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import RichText from "./RichText"
import Alert from "./Alert"

const BaseTemplate = () => {
    let previewMode = false
    const [contentData, setContentData] = useState<any>({})

    const paths = useParams()
    let slug = Object.values(paths).join('/')

    if (paths.path === 'preview') {
        previewMode = true
        slug =  slug.replace('preview/', '')
    }
    slug = window.location.pathname === '/' ? 'home-page' : slug

    const { data } = useContentAPI('entries', 'destinationPage', `${slug}`, previewMode)
    const { data: ErrorData } = useContentAPI('entries', 'destinationPage', `404-page`, previewMode)

    const addGATracking = () => {
        // Track PageView
        TrackGAPageView(contentData?.fields?.pageTitle);
    }

    useEffect(() => {
        if (ErrorData === null || data === null ) return
        
        data && data !== null ? setContentData(data) : setContentData(ErrorData)

        console.log(data)
        
        addGATracking()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, ErrorData])

    return (
        <HelmetProvider>
            {contentData && Object.entries(contentData).length > 0 &&
                <ContainerSection>
                    {/* Meta Tags Starts */}
                        <Helmet>
                            <title>{contentData?.fields?.pageTitle}</title>
                        </Helmet>
                    {/* Meta Tags Ends */}

                    {/* Banner Notification Block Starts */}
                        {contentData?.fields?.notification && (
                            <BannerNotification
                            content={contentData?.fields?.notification}
                            background={contentData?.fields?.notification?.fields?.backgroundColor}
                            closeIcon={contentData?.fields?.notification?.fields?.closeButton}
                            />
                        )}
                    {/* Banner Notification Block Ends */}

                    {/* Header Block Starts */}
                        <Header content={contentData?.fields?.header} />
                    {/* Header Block Ends */}

                    <ContainerSection>
                        {slug === "styleguide" && <StyleGuide />}

                        {contentData?.fields?.pageContainer?.map((block, index) => (
                            <div key={`${block?.sys?.contentType?.sys?.id}-${index}`}>
                            
                                {/* Hero Banner Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "heroBannerComponent" && 
                                        <HeroBanner content={block?.fields} />
                                    }
                                {/* Hero Banner Block Ends */}

                                {/* Contact us form Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "login" && 
                                        <ContactUsForm />
                                    }
                                {/* Contact us form Block Ends */}

                                {/* Page Banner Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "pageBanner" &&  
                                        <PageBanner content={block?.fields} background={block?.fields.backgroundVariantColor} />
                                    }
                                {/* Page Banner Block Ends */}

                                {/* Page Alert Starts */}
                                    {block?.sys?.contentType?.sys?.id === "notification" &&  
                                        <Alert content={block?.fields} background={block?.fields.backgroundColor} />
                                    }
                                {/* Page Alert Ends */}

                                {/* Rich Text Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "contentComponent" &&  
                                        <RichText content={documentToHtmlString(block?.fields?.richText)} />
                                    }
                                {/* Rich Text Block Ends */}
                                
                                {/* QuickLinks, Text Block, Forms&Transaction & Cards Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "blockInfoComponent" &&  (<>
                                        {block?.fields?.features ? <>
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "bunchOfLinksComponents" &&
                                                <QuickLinksBlock content={block?.fields} />
                                            }
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "blockInfoFeatureComponent" && <>
                                                {!block?.fields?.callOut &&
                                                    <Card background={block?.fields?.sectionBackgroundColor} cardBackground={block?.fields?.cardBackgroundColor} columnLayout={block?.fields?.cardLayout} border={block?.fields?.cardBorder} content={block?.fields} marginBottom={block?.fields?.componentSpacing} />
                                                }
                                                {block?.fields?.callOut &&
                                                    <Callout background={block?.fields?.sectionBackgroundColor} content={block?.fields} textAlignment={block?.fields?.cardTextAlign} marginBottom={block?.fields?.componentSpacing} />
                                                }
                                                </>
                                            }
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "ctaButtonComponent" && 
                                                <TextBlock content={block?.fields} background={block?.fields?.sectionBackgroundColor} pageContent={data} />
                                            }
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "pdfCardComponent" &&
                                                <FormsAndTransactions content={block?.fields} />
                                            }
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "socialMedia" &&
                                                <SocialMedia content={block?.fields} />
                                            }
                                            {block?.fields?.features[0]?.sys?.contentType?.sys?.id === "tabContainerComponent" &&
                                                <Events content={block?.fields} />
                                            }
                                            
                                        </>
                                        :
                                        <>
                                            {block?.fields?.assets ? <>
                                                {!block?.fields?.callOut &&
                                                    <Card background={block?.fields?.sectionBackgroundColor} cardBackground={block?.fields?.cardBackgroundColor} columnLayout={block?.fields?.cardLayout} border={block?.fields?.cardBorder} content={block?.fields} marginBottom={block?.fields?.componentSpacing} />
                                                }
                                                {block?.fields?.callOut &&
                                                    <Callout background={block?.fields?.sectionBackgroundColor} content={block?.fields} textAlignment={block?.fields?.cardTextAlign} marginBottom={block?.fields?.componentSpacing} />
                                                }
                                            </>
                                            :
                                            <>
                                                <TextBlock content={block?.fields} background={block?.fields?.sectionBackgroundColor} pageContent={data} /> 
                                            </>
                                            }
                                        </>}
                                        
                                    </>)}
                                {/* QuickLinks, Text Block, Forms&Transaction & Cards Block Ends */}

                                {/* Feature Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "contentWithCtaComponent" &&  (<>
                                        {block?.fields?.variant === "text" &&
                                            <FeatureBlock variant="text" background={block?.fields?.backgroundColor} content={block?.fields}/>
                                        }
                                        {block?.fields?.variant === "appStore" &&
                                            <FeatureBlock variant="appStore" background={block?.fields?.backgroundColor} content={block?.fields}/>
                                        }
                                    </>)}
                                    {block?.sys?.contentType?.sys?.id === "doubleBarreledComponent" &&  (<>
                                        {block?.fields?.columnLayoutVariant === "Image on the Left Col" &&
                                            <FeatureBlock variant="image-left" background={block?.fields?.backgroundColor} content={block?.fields} />
                                        }
                                        {block?.fields?.columnLayoutVariant === "Image on the Right Col" &&
                                            <FeatureBlock variant="image-right" background={block?.fields?.backgroundColor} content={block?.fields} />
                                        }
                                    </>)}
                                {/* Feature Block Ends */}

                                {/* Disclaimer Block Starts */}
                                    {block?.sys?.contentType?.sys?.id === "disclaimer" &&  
                                        <DisclaimerBlock border={true} fullWidth={false} content={block} />
                                    }
                                {/* Disclaimer Block Ends */}
                            </div>
                        ))}
                    </ContainerSection>

                    {/* Footer Starts */}
                        <Footer content={contentData?.fields?.footer} />
                    {/* Footer Ends */}
                </ContainerSection>
            }
        </HelmetProvider>
    )
}

export default BaseTemplate;
