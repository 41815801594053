import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import Headings from "../../Generic/Heading"
import {ReactComponent as InfoTealIcon} from '../../../Assets/infoTeal.svg'
import {ReactComponent as InfoPurpleIcon} from '../../../Assets/infoPurple.svg'
import {ReactComponent as WarningIcon} from '../../../Assets/warning.svg'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    background: string
    content?: any
}

/**
 * Alert Component
 * @param content - Content from the CMS.
 * @param background - Background of the Alert. Expected values: "teal" | "purple" | "orange", Default: "teal"
 */
const Alert = ({background = 'teal', content}: Props) => {
    background = background.toLowerCase()

    return (<>
        <div className="layoutSpacing">
            <div className={`w-100 pb-40 layout`}>
                {background === 'teal' && 
                    <div className="p-32 bg-teal-10 border border-bg-teal-100 rounded-2xl">
                        <div className="flex items-center gap-32">
                            <div>
                                <InfoTealIcon />
                            </div>
                            <div>
                                <Headings variant="H4" content={content?.title} classes="text-black mb-8" />
                                <Paragraph content={documentToHtmlString(content?.message)} classes="!text-base md:!text-md" />
                            </div>
                        </div>
                    </div>
                }
                {background === 'purple' && 
                    <div className="p-32 bg-purple-10 border border-bg-purple-100 rounded-2xl">
                        <div className="flex items-center gap-32">
                            <div>
                                <InfoPurpleIcon />
                            </div>
                            <div>
                                <Headings variant="H4" content={content?.title} classes="text-black mb-8" />
                                <Paragraph content={documentToHtmlString(content?.message)} classes="!text-base md:!text-md" />
                            </div>
                        </div>
                    </div>
                }
                {background === 'orange' && 
                    <div className="p-32 bg-orange-10 border border-bg-orange-100 rounded-2xl">
                        <div className="flex items-center gap-32">
                            <div>
                                <WarningIcon />
                            </div>
                            <div>
                                <Headings variant="H4" content={content?.title} classes="text-black mb-8" />
                                <Paragraph content={documentToHtmlString(content?.message)} classes="!text-base md:!text-md" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default Alert