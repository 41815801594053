import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import Breadcrumbs from "../Breadcrumbs"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    content?: any
    background?: string
}

/**
 * Page Banner Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "lightPurple" | "orange", Default: "purple"
 */
const PageBanner = ({content, background = 'lightPurple'}: Props) => {
    background = background.toLowerCase()
    return (<>
        {background === 'white' && 
            <div className={`layoutSpacing w-100 py-48 ${background === 'white' ? 'mb-0': 'mb-40'} bg-white`}>
                <div className="layout">
                    <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                        {content?.breadcrumbs && <Breadcrumbs content={content?.breadcrumbs} />}
                        <Headings variant="H1" content={content?.headlineWithContent[0]?.fields?.headline} classes="text-purple-100" />
                        <Paragraph content={documentToHtmlString(content?.headlineWithContent[0]?.fields?.description)} />
                    </div>
                </div>
            </div>
        }
        {background === 'lightpurple' && 
            <div className={`layoutSpacing w-100 py-48 mb-40 bg-purple-10`}>
                <div className="layout">
                    <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                        {content?.breadcrumbs && <Breadcrumbs content={content?.breadcrumbs} />}
                        <Headings variant="H1" content={content?.headlineWithContent[0]?.fields?.headline} classes="text-purple-100" />
                        <Paragraph content={documentToHtmlString(content?.headlineWithContent[0]?.fields?.description)} />
                    </div>
                </div>
            </div>
        }
        {background === 'orange' && 
            <div className={`layoutSpacing w-100 py-48 mb-40 bg-orange-10`}>
                <div className="layout">
                    <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                        {content?.breadcrumbs && <Breadcrumbs content={content?.breadcrumbs} />}
                        <Headings variant="H1" content={content?.headlineWithContent[0]?.fields?.headline} classes="text-purple-100" />
                        <Paragraph content={documentToHtmlString(content?.headlineWithContent[0]?.fields?.description)} />
                    </div>
                </div>
            </div>
        }
    </>)
}

export default PageBanner