import * as React from "react"
import { useState } from "react"
import Image from "../../Generic/Image"
import cal from '../../../Assets/calculate.svg'
import Paragraph from "../../Generic/Paragraph"
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon"
import {ReactComponent as TabNavLeft} from '../../../Assets/TabNavLeft.svg'
import {ReactComponent as TabNavRight} from '../../../Assets/TabNavRight.svg'
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import './style.css'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"

interface Props {
    content?: string
}

/**
 * Tabs Component
 * @param content - Content from the CMS.
 */
const Tabs = ({content}: Props) => {
    const { isMobile, isTablet, isDesktop } = useDeviceDetector()
    const [counter, setCounter] = useState(0)
    const [selectedImageUrl, setsSelectedImageUrl] = useState("")
    const [selectedText, setsSelectedText] = useState("")
    const totalTabs = 6
    const noOfTabsShown = isMobile ? 1 : isTablet ? 2 : isDesktop ? 5 : 1

    const toggleTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const element = document.querySelectorAll('.tabs')
        for(var i=0; i<element.length; i++) {
            element[i].classList.remove('active')
        }
        parentEle.classList.add('active')
    }

    const toggleMobileTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.mobileSelectedTab')
        parentEle.classList.toggle('active')
        parentEle.nextElementSibling.classList.toggle('active')
    }

    const selectTab = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const imageUrl = parentEle.children[0].src
        const text = parentEle.children[1].textContent
        setsSelectedImageUrl(imageUrl)
        setsSelectedText(text)
        const tabLayout = e.target.closest('.tabLayout')
        const mobileSelectedTab = tabLayout.querySelector('.mobileSelectedTab')
        mobileSelectedTab.classList.toggle('active')
        mobileSelectedTab.nextElementSibling.classList.toggle('active')
    }
    
    const navRight = (e: any) => {
        e.stopPropagation()
        setCounter(counter + 1)
        const tabLayout = e.target.closest('.tabLayout')
        const tabHeading = tabLayout.querySelector('.tabHeading')
        const tabs = tabLayout.querySelectorAll('.tabs')
        const lastTab = tabs[0]
        tabHeading.append(lastTab)
    }

    const navLeft = (e: any) => {
        e.stopPropagation()
        setCounter(counter - 1)
        const tabHeading = document.querySelector('.tabHeading')
        const tabs = document.querySelectorAll('.tabs')
        const lastTab = tabs[tabs.length - 1]
        tabHeading.prepend(lastTab)
    }

    return (<>
        <div className="layoutSpacing">
            <div className={`w-100 py-48 layout tabLayout`}>
                {!isMobile &&
                    <div className={`border border-neutral-50 rounded-2xl overflow-hidden`}>
                        <div className={`tabHeading flex border-b border-neutral-50 relative`}>
                            <div className={`tabs 1 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%] active`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="ESSS Defined Benefit Fund 1" classes="!text-xl"/>
                            </div>
                            <div className={`tabs 2 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 1" classes="!text-xl"/>
                            </div>
                            <div className={`tabs 3 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointe md:min-w-[50%] xl:min-w-[20%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 2" classes="!text-xl"/>
                            </div>
                            <div className={`tabs 4 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 3" classes="!text-xl"/>
                            </div>
                            <div className={`tabs 5 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 4" classes="!text-xl"/>
                            </div>
                            <div className={`tabs 6 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 5" classes="!text-xl"/>
                            </div>
                            {counter > 0 &&
                                <div className={`tabNavLeft flex justify-center items-center cursor-pointer absolute top-0 left-0 h-full p-12 pl-32 pr-80 bg-gradient-to-r from-white to-white-100 z-10`} onClick={(e) => {navLeft(e)}}>
                                    <TabNavLeft />
                                </div>
                            }
                            { totalTabs > noOfTabsShown && counter >= 0 && totalTabs - noOfTabsShown !== counter &&
                                <div className={`tabNavRight flex justify-center items-center cursor-pointer absolute top-0 right-0 h-full p-12 pl-80 pr-32 bg-gradient-to-l from-white to-white-100 z-10`} onClick={(e) => {navRight(e)}}>
                                    <TabNavRight />
                                </div>
                            }
                        </div>
                        <div className={`tabContent p-48`}>
                            <Paragraph content="Your final superannuation benefit is not impacted by investment performance as it is paid as a lump sum based on several factors, including your period of membership, part/full time status, final average salary, and elected contribution rate.
        <br/> <br/>For more information, refer to the ESSS Defined Benefit Fund Product Disclosure Statement (PDS)." classes="mb-24"/>
                            <PrimaryButtonWithIcon name="PDS and handbooks" link="/" variant="dark" classes="mb-8" />
                        </div>
                    </div>
                }

                {isMobile && totalTabs <= 2 &&
                    <div className={`border border-neutral-50 rounded-2xl overflow-hidden`}>
                        <div className={`tabHeading flex border-b border-neutral-50 relative`}>
                            <div className={`tabs 1 flex flex-1 justify-center items-center gap-12 p-24 cursor-pointer min-w-[50%] xl:min-w-[20%] active`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="ESSS Defined Benefit Fund 1" classes="!text-lg" />
                            </div>
                            <div className={`tabs 2 flex flex-1 justify-center items-center gap-12 p-24 cursor-pointer min-w-[50%]`} onClick={(e) => {toggleTabs(e)}}>
                                <Image imageUrl={cal} altText="TabIcon" width="30" />
                                <Paragraph content="State Super 1" classes="!text-lg" />
                            </div>
                        </div>
                        <div className={`tabContent p-24`}>
                            <Paragraph content="Your final superannuation benefit is not impacted by investment performance as it is paid as a lump sum based on several factors, including your period of membership, part/full time status, final average salary, and elected contribution rate.
        <br/> <br/>For more information, refer to the ESSS Defined Benefit Fund Product Disclosure Statement (PDS)." classes="mb-24"/>
                            <PrimaryButtonWithIcon name="PDS and handbooks" link="/" variant="dark" classes="mb-8" />
                        </div>
                    </div>
                }

                {isMobile && totalTabs > 2 &&
                    <div className={`border border-neutral-50 rounded-2xl overflow-hidden`}>
                        <div className={`relative`}>
                            <div className={`mobileSelectedTab flex justify-between items-center p-24 border-b border-neutral-50`} onClick={(e) => {toggleMobileTabs(e)}}>
                                <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                    <Image imageUrl={selectedImageUrl} altText="TabIcon" width="30" />
                                    <Paragraph content={selectedText} classes="!text-lg" />
                                </div>
                                <div className="">
                                    <ArrowUpIcon />
                                    <ArrowDownIcon />
                                </div>
                            </div>
                            <div className={`mobileTabHeading flex flex-col border-b border-neutral-50 absolute top-full left-0 bg-white w-full`}>
                                <div className={`tabs flex justify-start items-center gap-12 p-24 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                    <Image imageUrl={cal} altText="TabIcon" width="30" />
                                    <Paragraph content="ESSS Defined Benefit Fund" classes="!text-lg" />
                                </div>
                                <div className={`tabs flex justify-start items-center gap-12 p-24 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                    <Image imageUrl={cal} altText="TabIcon" width="30" />
                                    <Paragraph content="State Super" classes="!text-lg" />
                                </div>
                                <div className={`tabs flex justify-start items-center gap-12 p-24 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                    <Image imageUrl={cal} altText="TabIcon" width="30" />
                                    <Paragraph content="State Super" classes="!text-lg" />
                                </div>
                            </div>
                        </div>
                        <div className={`tabContent p-24`}>
                            <Paragraph content="Your final superannuation benefit is not impacted by investment performance as it is paid as a lump sum based on several factors, including your period of membership, part/full time status, final average salary, and elected contribution rate.
            <br/> <br/>For more information, refer to the ESSS Defined Benefit Fund Product Disclosure Statement (PDS)." classes="mb-24"/>
                            <PrimaryButtonWithIcon name="PDS and handbooks" link="/" variant="dark" classes="mb-8" />
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default Tabs