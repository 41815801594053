import * as React from "react"
import { ReactComponent as Spinner } from '../../../../../Assets/spinner.svg'
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import { TrackGAEvent } from "../../../../GoogleAnalytics"

interface Props {
  name: string
  variant: string
  buttonType?: "button" | "submit" | "reset"
  link?: string
  loading?: boolean
  disabled?: boolean
  classes?: string
}

/**
 * Primary Button With icon Component
 * @param name - Name of the button.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param buttonType (optional) - Type of the button. Expected values:  "button" | "submit" | "reset", Default: "button"
 * @param link (optional) - Page to be navigate on click of the button.
 * @param loading (optional) - Loading of the button. Expected values:  true | false
 * @param disabled (optional) - Button to be disabled. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const PrimaryButtonWithIcon = ({ name, variant, buttonType = "button", link, loading, disabled, classes }: Props) => {
  const handleClick = (e: any, name: string, link: string) => {
    e.preventDefault()
    e.stopPropagation()
    TrackGAEvent('ButtonClick', 'click', name)
    if(link) {
      if (link?.includes('scroll#')) {
        const id = link.replace('scroll#', '')
        const targetElement = document.getElementById(id)
        targetElement?.classList.remove('hidden')
        const top = targetElement?.offsetTop
        window.scrollTo({top: top, behavior: 'smooth'})
      } else {
        window.location.href = link
      }
    }
  }
  return (
    <>
      {variant === "light" && (<>
        <button data-testid="ctaBtn" type={buttonType} className={`flex justify-center items-center text-base md:text-md text-purple-100 rounded-lg
            px-24 py-12
            ${loading ? 'bg-purple-pressedLight pointer-events-none' : 'bg-white border-4 border-transparent' }
            hover:bg-purple-hoverLight 
            focus:bg-purple-hoverLight focus:border-4 focus:border-purple-100 focus:border-opacity-20 
            active:bg-purple-pressedLight
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-purple-pressedLight
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={buttonType === 'button' ? (e: any) => handleClick(e, name, link) : undefined}
        >
          {!loading && <>
            <span className="mr-12">{name}</span>
            <ArrowForwardIcon />
          </>}
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
      {variant === "dark" && (<>
        <button type={buttonType} className={`flex justify-center items-center text-base md:text-md text-white rounded-lg 
            px-24 py-12
            ${loading ? 'bg-purple-pressedDark pointer-events-none' : 'bg-purple-100 border-4 border-transparent' }
            hover:bg-purple-hoverDark 
            focus:bg-purple-hoverDark focus:border-4 focus:border-purple-100 focus:border-opacity-20 
            active:bg-purple-pressedDark 
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-purple-pressedDark
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={buttonType === 'button' ? (e: any) => handleClick(e, name, link) : undefined}
        >
          {!loading && <>
            <span className="mr-12">{name}</span>
            <ArrowForwardIcon />
          </>}
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
    </>
  )
}

export default PrimaryButtonWithIcon
