import { useEffect, useState } from "react"

export const useDeviceDetector = () => {
    const [device, setDevice] = useState({
      isMobile: false,
      isDesktop: false,
      isTablet: false
    })

    const handleWindowResize = () => {
      const resolution = window.innerWidth
      const isMobile = resolution >= 320 && resolution <= 767
      const isTablet = resolution >= 768 && resolution <= 1024
      const isDesktop = !isMobile && !isTablet
  
      setDevice({
        isMobile,
        isTablet,
        isDesktop
      })
    }

    useEffect(() => {
        handleWindowResize()
        window.addEventListener("resize", handleWindowResize)
    }, [])

    return { ...device }
}