import * as React from "react"

interface Props {
    content: string
    classes?: string
}

/**
 * Paragraph Component
 * @param content (optional) - Text of the paragraph.
 * @param classes (optional) - Tailwindcss classes to customize it.
 */
const RichText = ({content, classes}: Props) => {

    return (
        <div className="layoutSpacing">
            <div className="layout py-48">
            <p data-testid="paragraph"  className={`text-container text-md ${classes ? classes : ''}`} dangerouslySetInnerHTML={{__html: content}}></p>
           </div>
        </div>
    )
    
}

export default RichText