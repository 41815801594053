import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import Image from "../../Generic/Image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { RenderButton } from "../../Generic/Button/RenderButton"

interface Props {
    variant: string
    background?: string
    content?: any
}

/**
 * Feature Button Component
 * @param variant - Variant of the component. Expected values: "text" | "appStore" | "image-left" | "image-right"
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" | "teal", Default: "white"
 */
const FeatureBlock = ({variant, content, background = 'purple'}: Props) => {
    background = background.toLowerCase()
    return (<>
        <div className="layoutSpacing">
            <div data-testid="FeatureBlock" className={`w-100 pb-80 layout`}>
                {variant === 'text' && background === 'white' &&
                    <div data-testid="FeatureColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-white rounded-2xl border border-neutral-50">
                        <div className="flex-1 pr-16">
                            <Headings  content={content?.headline} variant="H3" classes="text-black pb-16" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                        </div>
                        <div data-testid="buttonComponent">
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'text' && background === 'purple' &&
                    <div data-testid="FeaturePurpleColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-purple-100 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-white pb-16" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-white" />
                        </div>
                        <div>
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'text' && background === 'orange' &&
                    <div data-testid="FeatureOrangeColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-orange-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                        </div>
                        <div>
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'text' && background === 'lightpurple' &&
                    <div data-testid="FeatureLightPurpleColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-purple-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                        </div>
                        <div>
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'text' && background === 'teal' &&
                    <div data-testid="FeatureTealColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-teal-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                        </div>
                        <div>
                            {content?.ctaComponet?.map((item, index) => 
                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                    <RenderButton item={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {variant === 'appStore' && background === 'white' &&
                    <div data-testid="appstoreWhiteColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-white rounded-2xl border border-neutral-50">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                        </div>
                        <div className="flex flex-wrap gap-8" data-testid="ctaButton">
                            {content?.ctaAppStoreLink?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                            {content?.ctaGoogleplay?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'appStore' && background === 'purple' &&
                    <div data-testid="appstorePurpleColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-purple-100 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-white pb-16" />
                        </div>
                        <div className="flex flex-wrap gap-8">
                            {content?.ctaAppStoreLink?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                            {content?.ctaGoogleplay?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'appStore' && background === 'orange' &&
                    <div data-testid="appstoreOrangeColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-orange-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                        </div>
                        <div className="flex flex-wrap gap-8">
                            {content?.ctaAppStoreLink?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                            {content?.ctaGoogleplay?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'appStore' && background === 'lightpurple' &&
                    <div data-testid="appstoreLightpurpleColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-purple-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                        </div>
                        <div className="flex flex-wrap gap-8">
                        {content?.ctaAppStoreLink?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                            {content?.ctaGoogleplay?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'appStore' && background === 'teal' &&
                    <div  data-testid="appstoreTealColor" className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-16 p-32 lg:p-48 bg-teal-10 rounded-2xl">
                        <div className="flex-1 pr-16">
                            <Headings content={content?.headline} variant="H3" classes="text-black pb-16" />
                        </div>
                        <div className="flex flex-wrap gap-8">
                            {content?.ctaAppStoreLink?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                            {content?.ctaGoogleplay?.content?.map((item, index) => (
                                <div key={`${item?.sys?.id}-${index}`}>
                                    {Object.keys(item?.data).length > 0 && 
                                        <a href="/" aria-label={item?.data?.target?.fields?.file?.title}>
                                            <Image imageUrl={item?.data?.target?.fields?.file?.url} altText={item?.data?.target?.fields?.file?.title} />
                                        </a>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {variant === 'image-left' && background === 'white' &&
                    <div data-testid="blockWhiteColor" className={`flex flex-col xl:flex-row items-center bg-white border border-neutral-50 rounded-2xl overflow-hidden`}>
                        <div data-testid="image" aria-label={content?.columnImage?.file?.title} className="flex-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`}}>
                        </div>
                        <div className="flex-1">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-black" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                                <div className="pt-24" data-testid="featureButtonComponent">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-left' && background === 'purple' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-purple-100 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-white" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-white" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-left' && background === 'orange' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-orange-10 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-black" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-right' && background === 'white' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-white border border-neutral-50 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1 xl:order-0">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-black" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-right' && background === 'purple' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-purple-100 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1 xl:order-0">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-white" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-white" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-right' && background === 'orange' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-orange-10 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1 xl:order-0">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-black" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {variant === 'image-right' && background === 'lightpurple' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-purple-10 rounded-2xl overflow-hidden`}>
                    <div aria-label={content?.columnImage?.file?.title} className="flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                    </div>
                    <div className="flex-1 xl:order-0">
                        <div className="p-32">
                            <Headings content={content?.headline} variant="H3" classes="text-black" />
                            <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                            <div className="pt-24">
                                {content?.buttonComponent?.map((item, index) => 
                                    <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                        <RenderButton item={item} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {variant === 'image-right' && background === 'teal' &&
                    <div className={`flex flex-col xl:flex-row items-center bg-teal-10 rounded-2xl overflow-hidden`}>
                        <div aria-label={content?.columnImage?.file?.title} className="flex-1 xl:order-1 self-stretch aspect-video bg-no-repeat bg-cover" style={{backgroundImage: `url(${content?.columnImage?.fields?.file?.url})`, backgroundPosition: 'center'}}>
                        </div>
                        <div className="flex-1 xl:order-0">
                            <div className="p-32">
                                <Headings content={content?.headline} variant="H3" classes="text-black" />
                                <Paragraph content={documentToHtmlString(content?.bodyText)} classes="text-black" />
                                <div className="pt-24">
                                    {content?.buttonComponent?.map((item, index) => 
                                        <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                            <RenderButton item={item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default FeatureBlock