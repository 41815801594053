import * as React from "react"
import { TrackGAEvent } from "../../../../GoogleAnalytics"

interface Props {
  name: string
  link: string
  variant: string
  classes?: string
  fontColor?: string
  underline?: boolean
}

/**
 * Text Button Component
 * @param name - Name of the text link.
 * @param link - Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param fontColor (optional) - Color of the text. Expected Tailwindcss classes
 * @param underline (optional) - Text underline. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const TextButton = ({ name, link, variant, fontColor, underline, classes }: Props) => {
  const handleClick = (e: any, name: string, link: string) => {
    e.preventDefault()
    e.stopPropagation()
    TrackGAEvent('ButtonClick', 'click', name)
    if(link) {
      window.location.href = link
    }
  }
  return (
    <>
      {variant === "light" && (<>
        <a href={link} aria-label={name} className={`flex items-start text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-white'}
          ${classes ? classes : 'justify-center'}
          focus:focus:border focus:border-white focus:border-opacity-20 
          active:text-primary-hoverLight`}
          onClick={(e: any) => handleClick(e, name, link)}
        >
          <span className={`${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
        </a>
        </>
      )}
      {variant === "dark" && (<>
        <a href={link} aria-label={name} className={`flex items-start text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-purple-100'}
          ${classes ? classes : 'justify-center'}
          focus:focus:border focus:border-purple-100 focus:border-opacity-20 
          active:text-primary-hoverDark`}
          onClick={(e: any) => handleClick(e, name, link)}
        >
          <span className={`${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
        </a>
        </>
      )}
    </>
  )
}

export default TextButton
