import * as React from "react"
import Image from "../../Generic/Image"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    content?: any
}

/**
 * SocialMedia Component
 * @param content - Content from the CMS.
 */
const SocialMedia = ({content}: Props) => {
    return (<>
        <div className={`layoutSpacing w-100 mb-40 bg-white`}>
            <div className="layout">
                <div className={`flex ${content?.texColtLayout ? 'flex-col xl:flex-row gap-24' : 'flex-col w-100 md:w-75 xl:w-60' }`}>
                    {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100" />}
                    {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                    <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText  || content?.description)} classes="" />
                </div>
                {content?.features && <> 
                    <div className="flex flex-col gap-16">
                        {content?.features?.map((item) =>
                            <div key={item?.sys?.id}>
                                <a href={item?.fields?.socialMediaUrl} className="flex items-center gap-8">
                                    <Image imageUrl={item?.fields?.socialMediaIcon?.fields?.file?.url} altText={item?.fields?.socialMediaPlatform} />
                                    <span>{item?.fields?.socialMediaPlatform}</span>
                                </a>
                            </div>
                        )}
                    </div>
                </>
                }
            </div>
        </div>
    </>)
}

export default SocialMedia