import * as React from "react"
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import { ReactComponent as ArrowBackIcon } from '../../../../../Assets/arrowBack.svg'
import { TrackGAEvent } from "../../../../GoogleAnalytics"

interface Props {
  name: string
  link?: string
  variant: string
  arrowDirection?: string
  iconPosition?: string
  fontColor?: string
  underline?: boolean
  classes?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Text Button With Icon Component
 * @param name - Name of the text link.
 * @param link (optional)- Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param arrowDirection (optional) - Direction for the arrow. Expected values: "left" | "right"
 * @param iconPosition (optional) - Position of the icon. Expected values: "start" | "end"
 * @param underline (optional) - Text underline. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const TextButtonWithIcon = ({ name, link, variant, arrowDirection = 'right', iconPosition = 'end', fontColor, underline, classes, onClick  }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, name: string, link: string) => {
    e.preventDefault()
    e.stopPropagation()
    TrackGAEvent('ButtonClick', 'click', name)
    if(link) {
      window.location.href = link
    }
    if(onClick) {
      onClick(e)
    }
  }
  return (
    <>
      {variant === "light" && (<>
        <div className="flex">
          <a href={link} aria-label={name} className={`flex text-base md:text-md rounded-lg border-4 border-transparent font-semibold
            ${fontColor ? fontColor : 'text-white'}
            ${classes ? classes : ''}
            focus:focus:border-4 focus:border-white focus:border-opacity-20 
            active:text-primary-hoverLight
            visited:text-primary-hoverLight`}
            onClick={(e: any) => handleClick(e, name, link)}
          >
            {iconPosition === 'start' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
            <span className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
            {iconPosition === 'end' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
          </a>
        </div>
        </>
      )}
      {variant === "dark" && (<>
        <div className="flex">
          <a href={link} aria-label={name} className={`flex text-base md:text-md rounded-lg border-4 border-transparent font-semibold
            ${fontColor ? fontColor : 'text-purple-100'}
            ${classes ? classes : ''}
            focus:focus:border-4 focus:border-purple-100 focus:border-opacity-20 
            active:text-primary-hoverDark`}
            onClick={(e: any) => handleClick(e, name, link)}
          >
            {iconPosition === 'start' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
            <span className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
            {iconPosition === 'end' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
          </a>
        </div>
        </>
      )}
    </>
  )
}

export default TextButtonWithIcon
