import * as React from "react"

interface Props {
    content: string
    classes?: string
}

/**
 * Paragraph Component
 * @param content (optional) - Text of the paragraph.
 * @param classes (optional) - Tailwindcss classes to customize it.
 */
const Paragraph = ({content, classes}: Props) => {

    return (<>
           <p data-testid="paragraph"  className={`text-container text-md ${classes ? classes : ''}`} dangerouslySetInnerHTML={{__html: content}}></p>
        </>
    )
    
}

export default Paragraph