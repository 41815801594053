import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const webVitals = (vitals: any) => {
  console.log(`${vitals.name} - ${vitals.value}`)
  console.log(`${vitals.name} - ${vitals.value/100}`)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// FCP - <=1.8s, LCP <=2.5s, CLS <=0.1s, TTFB <=0.8s
reportWebVitals(webVitals)
