import * as React from "react"
import { useEffect, useState } from "react"
import InputComp from "../../Generic/Form/Input"
import TextAreaWithClose from "../../Generic/Form/Textarea"
import DropdownComp from "../../Generic/Form/Dropdown"
import { useContentAPI } from "../../../Hooks/useAPI"
import PrimaryButton from "../../Generic/Button/Primary/Button"
import Paragraph from "../../Generic/Paragraph"
import { enquiryFormValidation } from "../../../Hooks/useValidation"
// import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

/**
 * Contact Us Enquiry Form
 * 
 */

const ContactUsForm = () => {
    const { data } = useContentAPI('contentType', 'login', '', false)
    const { validations } = enquiryFormValidation()
    const [invalidFocus, setInvalidFocus] = useState(false)
    const [validationData, setValidationData] = useState(validations)
    // const [token, setToken] = useState("")
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    // const siteKey = "1234"

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
          window.clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            callback(...args)
          }, wait)
        }
      }

    const validateFields = (name: string, value: string) => {
        // const dataField = data?.data?.fields?.filter((item) => item?.id === name)
        let field = validationData[name]

        if (validationData[name]?.required && value === "") {
            validationData[name] = {
                ...validationData[name],
                error: true,
                errorMsg: validationData[name]?.requiredMsg
            }
            setValidationData({
                ...validationData
            })
        }
        if (value !== "") {
            validationData[name] = {
                ...validationData[name],
                error: false,
                errorMsg: ""
            }
            const regex = field.pattern ? field.pattern : ''
            if(regex && regex !== "") {
                const result = regex.test(value)
                if(!result) {
                    validationData[name] = {
                        ...validationData[name],
                        error: true,
                        errorMsg: validationData[name]?.validationMsg
                    }
                }
            }
            setValidationData({
                ...validationData
            })
        }
    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        let obj = {}
        const form = e.target.closest('.enquiryForm')
        const formDetails: any = new FormData(form)
        for (const [key, value] of formDetails) {
            obj[key] = value
            validateFields(key, value)
        }
        setInvalidFocus(true)
        setRefreshReCaptcha(!refreshReCaptcha)
        // if (token) {
        //     const {data, error } = AddEntryAPI('login', obj)
        //     console.log("apiData", data)
        //     console.log("apieroor", error)
        // }
    }

    const onFieldsChange = debounce((name, value) => {
        validateFields(name, value)
    }, 1000)

    // const setReCaptchaToken = (getToken) => {
    //     setToken(getToken)
    // }

    useEffect(() => {
        if (invalidFocus) {
            const form = document.querySelector('.enquiryForm')
            const errorElement: any = form.querySelector('.invalid')
            errorElement !== null && errorElement.focus()
            setInvalidFocus(false)
        }
    }, [validationData, invalidFocus])

    return (<>
        <div className="layoutSpacing">
            <div id="contactform" className="hidden w-100 layout essForms py-24">
                <div className="text-right">
                    <Paragraph content="* Mandatory fields" classes="!text-base" />
                </div>
                <form className="enquiryForm">
                    {data?.data?.fields?.map((field, index) => (<div key={`${field.id}-${index}`}>
                        {field?.id !== "componentName" && <>
                            {field?.type === 'Array' && 
                                <DropdownComp 
                                    label={`${field?.name} ${validationData[field?.id]?.required ? '*' : ''}`}
                                    name={field?.id}
                                    error={validationData[field?.id]?.error}
                                    errorMessage={validationData[field?.id]?.errorMsg}
                                    helpText={field?.helpText}
                                    options={field?.items?.validations[0].in}
                                    withCheckBox={false}
                                    required={validationData[field?.id]?.required}
                                    onSelect={(value)=> onFieldsChange(field?.id, value)}
                                />
                            }
                            {field?.type === 'Symbol' && <>
                                <InputComp
                                    label={`${field?.name} ${validationData[field?.id]?.required ? '*' : ''}`}
                                    name={field?.id}
                                    placeholder={field?.name}
                                    error={validationData[field?.id]?.error}
                                    errorMessage={validationData[field?.id]?.errorMsg}
                                    helpText={field?.helpText}
                                    variant="text"
                                    required={validationData[field?.id]?.required}
                                    onChange={(name, value)=> onFieldsChange(name, value)}
                                />
                            </>}
                            {field?.type === 'Integer' && 
                                <InputComp
                                    label={`${field?.name} ${validationData[field?.id]?.required ? '*' : ''}`}
                                    name={field?.id}
                                    placeholder={field?.name}
                                    error={validationData[field?.id]?.error}
                                    errorMessage={validationData[field?.id]?.errorMsg}
                                    helpText={validationData[field?.id]?.helpText}
                                    variant="text"
                                    required={field?.required}
                                    onChange={(name, value)=> onFieldsChange(name, value)}
                                />
                            }
                            {field?.type === 'Text' && 
                                <TextAreaWithClose
                                    label={`${field?.name} ${validationData[field?.id]?.required ? '*' : ''}`}
                                    name={field?.id}
                                    placeholder={field?.name}
                                    className="mb-4"
                                    error={validationData[field?.id]?.error}
                                    errorMessage={validationData[field?.id]?.errorMsg}
                                    helpText={field?.helpText}
                                    onChange={(name, value)=> onFieldsChange(name, value)}
                                />
                            }
                            {field?.type === 'Date' && 
                                <InputComp
                                    label={`${field?.name} ${validationData[field?.id]?.required ? '*' : ''}`}
                                    name={field?.id}
                                    placeholder={field?.name}
                                    error={validationData[field?.id]?.error}
                                    errorMessage={validationData[field?.id]?.errorMsg}
                                    helpText={field?.helpText}
                                    variant="date"
                                    required={validationData[field?.id]?.required}
                                    onChange={(name, value)=> onFieldsChange(name, value)}
                                />
                            }
                            </>
                        }
                        </div>
                    ))}
                    {/* <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                        <GoogleReCaptcha onVerify={setReCaptchaToken} refreshReCaptcha={refreshReCaptcha}/>
                    </GoogleReCaptchaProvider> */}
                    <div className="w-max" onClick={(e) => handleSubmit(e)}>
                        <PrimaryButton variant="dark" name="Submit" buttonType="submit" classes="mt-24" />
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default ContactUsForm