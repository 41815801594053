const enquiryFormValidation = () => {

    let validations = []
    validations["whatIsYourEnquiryAbout"] = {
        id: "whatIsYourEnquiryAbout",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{6,9}$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers(0-9),(min: 6, max: 9 characters allowed)"
    }
    validations["fullName"] = {
        id: "fullName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["emailAddress"] = {
        id: "emailAddress",
        pattern: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address. Format: xyz@example.com"
    }
    validations["phoneNumber"] = {
        id: "phoneNumber",
        pattern: /^\+([0-9]{1,2})?([0-9]{10})$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers[0-9] and '+' (min: 10, max: 13 characters allowed) Format: +991234567890"
    }
    validations["yourEnquiry"] = {
        id: "yourEnquiry",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }

    return { validations }
    
}

export { enquiryFormValidation }