import * as React from "react"
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import { ReactComponent as CalculateIcon } from '../../../../../Assets/calculate.svg'
import { TrackGAEvent } from "../../../../GoogleAnalytics"

interface Props {
  name: string
  link: string
  variant?: string
  classes?: string
}

/**
 * Card Button Component
 * @param name - Name of the button.
 * @param link - Page to be navigate on click of the button.
 * @param variant (optional) - Variation of the button. Expected values: "" | "stacked"
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const CardButton = ({ name, link, variant, classes }: Props) => {
  const handleClick = (e: any, name: string, link: string) => {
    e.preventDefault()
    e.stopPropagation()
    TrackGAEvent('ButtonClick', 'click', name)
    if(link) {
      window.location.href = link
    }
  }

  return (
    <>  
      <a href={link} aria-label={name} className={`flex flex-col md:flex-row text-md md:text-lg font-semibold text-purple-100 rounded-2xl bg-white border border-neutral-50
        p-24 w-100
        ${variant === 'stacked' ? 'md:flex-col items-start' : 'items-start md:items-center'}
        hover:border-purple-100 
        focus:border-4 focus:border-purple-100 focus:border-opacity-20 ${classes ? classes : ''}`}
        onClick={(e: any) => handleClick(e, name, link)}
      >
        <CalculateIcon className="min-w-37 min-h-50 max-w-37 max-h-50" />
        <span className={`flex justify-between items-center w-100  
          ${variant === 'stacked' ? 'pl-0 pt-16' : 'pl-0 md:pl-16 pt-16 md:pt-0'}`}>
          {name}
          <ArrowForwardIcon className="ml-12"/>
        </span>
      </a>
    </>
  )
}

export default CardButton
