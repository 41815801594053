import * as React from "react"
import TextButton from "../../Generic/Button/Text/Button"
import Paragraph from "../../Generic/Paragraph"
import { useEffect, useState } from "react"

interface Props {
    content?: any
}

/**
 * Breadcrumbs Component
 * @param content - Content from the CMS.
 */
const Breadcrumbs = ({content}: Props) => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    let array = []

    const iterateObject = (obj) => {
        const asdf = {title: obj['title'], slug: obj['slug'], link: obj['externelLink']}
        !array.includes(asdf) && array.push(asdf)
        const arr = array.filter((item) => item.title === obj['title'] && item.slug === obj['slug'])
        arr.length === 0 && array.push(asdf)
        if (obj['parentPage']) {
            iterateObject(obj['parentPage']?.fields)
        } else {
            setLoad(true)
        }
    }

    useEffect(() => {
        if (!load) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            array = []
            iterateObject(content[0]?.fields)
            const finalData = array.reverse()
            setData(finalData)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])

    return (<>
        <div className={`w-100 flex flex-wrap items-center gap-8 mb-32`}>
            <div>
                <TextButton variant="dark" name="Home" link="/" fontColor="text-black" underline={true} classes="!font-normal w-max" />
            </div>
            <div>
                <Paragraph content="/" />
            </div>
            {data && data.map((item, index) => 
                <div className="flex gap-12" key={`${item?.title}-${index}`}>
                    {index !== data.length - 1 ?
                        <div className="flex gap-12" key={`${item?.title}-${index}`}>
                            <div>
                                <TextButton variant="dark" name={item?.title} link={item?.link} fontColor="text-black" underline={true} classes="!font-normal w-max" />
                            </div>
                            <div>
                                <Paragraph content="/" />
                            </div>
                        </div>
                        :
                        <div>
                            <Paragraph content={content[0]?.fields?.title} classes=" w-max" />
                        </div>
                    }
                </div>
            )}
        </div>
        </>
    )
}

export default Breadcrumbs