import React from 'react';

interface TextareaProps {
  label?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  helpText?:string;
  isDisabled?: boolean;
  onChange?: (name: string, value: string) => void;
}

const TextAreaWithClose = (props:TextareaProps) => {
  const { label, name, placeholder, className, error, errorMessage, helpText, isDisabled = false, onChange } = props;

  const clearText = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const parentElement = e.target.closest('.textarea')
    parentElement.querySelector('textarea').value = ''
  }
  return (
    <div className={`textarea ${className ? className : ''} py-16`}>
      <div className="relative">
        {label && <label className="block text-neutral-100 text-md mb-8">{label}</label>}
        <textarea 
          data-testid="textarea"
          rows={6}
          name={name}
          className={`border rounded-md md:w-100 p-16 text-neutral-90 mb-8
            focus:outline-none 
            focus-within:shadow-lg
            focus-visible:ring-purple
            focus:border-purple-100
            focus:text-neutral-100
            hover:outline-none 
            hover:border-purple-100
            hover:text-neutral-100 
            ${error ?  'border-danger-100 invalid' : 'border-neutral-50'}`}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <button
          className="
            absolute 
            right-5
            text-neutral-90 
            bottom-5
            text-base
          "
          onClick={(e) => clearText(e)}
        >
          <span className='close'>✖</span> Clear
        </button>
      </div>
      {error && errorMessage && <small className={`${error && errorMessage ? 'error' : ''} block text-danger-100 text-base mb-8`}>{errorMessage}</small>}
      {helpText && <small className="block text-neutral-100 text-sm">{helpText}</small>}
    </div>
  );
};

export default TextAreaWithClose;
