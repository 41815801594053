import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import Headings from "../../Generic/Heading"
import { ReactComponent as LocationIcon } from "../../../Assets/location.svg"
import { ReactComponent as CalendarIcon } from "../../../Assets/calendar.svg"
import Tag from "../../Generic/Tag"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { useEffect, useState } from "react"
import EventCardsCarousel from "./Carousel"

interface Props {
  content?: any
  cardBackground?: string
  columnLayout?: string
  border?: boolean
  selectedTabs?: any
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const EventCards = ({ content, cardBackground = 'white', columnLayout = '2', border = false, selectedTabs }: Props) => {
    const [data, setData] = useState([])
    const bgCardColor = cardBackground === 'purple' ? 'bg-purple-10' : cardBackground === 'orange' ? 'bg-orange-10' : cardBackground === 'teal' ? 'bg-teal-10' : 'bg-white'
    const columns = columnLayout === '3' ? 'xl:w-[32%]' : columnLayout === '4' ? 'xl:w-[23.6%]' : 'xl:w-[49%]'
    const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`

    useEffect(() => {
        if (content && selectedTabs.length > 0) {
            let selectedData = []
        
            selectedTabs?.forEach((selected) => {
                const tabs = content?.filter((group) => group?.fields?.headline === selected?.tabGroup)
                tabs[0]?.fields?.tabsGroup?.forEach((item) => {
                    if (item?.fields?.tabTitle === selected?.tabItem) {
                        selectedData.push(item)
                    }
                })
            })
            setData([...selectedData])
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTabs, content])
  
  return (
    <>
    <div className="layoutSpacing">
        <div className="w-full layout py-32  overflow-hidden">
            {content[0]?.fields?.carousel ?
                <EventCardsCarousel data={data} content={content} />
                :
                <div className="flex flex-wrap justify-start gap-24">
                    {data?.map((item) => item?.fields?.tabContent?.map((tabContent, index) =>
                        <div key={`${tabContent?.fields?.title}-${index}`} className={`flex ${border ? 'border border-neutral-50' : ''} rounded-2xl overflow-hidden hover:border-purple-100 ${bgCardColor} ${layout}`}>
                            <div className="p-24 md:p-32">
                                <Headings variant="H4" content={tabContent?.fields?.title} classes="text-purple-100 pb-16" />
                                <Paragraph content={documentToHtmlString(tabContent?.fields?.descriptionOfEvents)} classes="line-clamp-3" />
                                {tabContent?.fields?.eventTags && 
                                    <div className="pt-32">
                                        <Tag name={tabContent?.fields?.eventTags} />
                                    </div>
                                }
                                <div className="py-32 grow">
                                    <div className="flex items-center gap-8 pb-16">
                                        <LocationIcon />
                                        <Paragraph content={tabContent?.fields?.locationTitle}/>
                                    </div>
                                    <div className="flex items-center gap-8">
                                        <CalendarIcon />
                                        <Paragraph content={tabContent?.fields?.dateOfTheEvent} />
                                    </div>
                                </div>
                                <TextButtonWithIcon variant="dark" link="/" name={tabContent?.fields?.registerText} />
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    </div>
    </>
  )
}

export default EventCards
