import * as React from "react";
import Headings from "../Generic/Heading";
import Paragraph from "../Generic/Paragraph";
import PrimaryButton from "../Generic/Button/Primary/Button";
import PrimaryButtonWithIcon from "../Generic/Button/Primary/ButtonWithIcon";
import PrimaryButtonOnlyIcon from "../Generic/Button/Primary/IconButton";
import OutlineButton from "../Generic/Button/Outline/Button";
import OutlineButtonWithIcon from "../Generic/Button/Outline/ButtonWithIcon";
import OutlineButtonOnlyIcon from "../Generic/Button/Outline/IconButton";
import OutlineButtonSearchIcon from "../Generic/Button/Outline/SearchIconButton";
import SecondaryButton from "../Generic/Button/Secondary/Button";
import SecondaryButtonWithIcon from "../Generic/Button/Secondary/ButtonWithIcon";
import SecondaryButtonOnlyIcon from "../Generic/Button/Secondary/IconButton";
import TextButton from "../Generic/Button/Text/Button";
import TextButtonWithIcon from "../Generic/Button/Text/ButtonWithIcon";
import CardButton from "../Generic/Button/Card/Button";
import DisclaimerBlock from "./Disclaimer";
import FeatureBlock from "./Feature";
import QuickLinksBlock from "./QuickLinks";
import Tag from "../Generic/Tag";
import IconList from "../Generic/IconList";
import FormsAndTransactions from "./FormsAndTransaction";
import Video from "../Generic/Video";
import Accordion from "./Accordion";
import PageBanner from "./PageBanner";
import Alert from "./Alert";
import BannerNotification from "./BannerNotification";
import Tabs from "./Tabs";
import TextBlock from "./TextBlock";
import Callout from "./Callout";
import Listing from "./Listing";
import TabSelector from "../Generic/TabSelector";
import Card from "./Card";
import EventCards from "./Events/Cards";
import EventCardsCarousel from "./Events/Carousel";
import EventDetails from "./Events/Detail";
import Events from "./Events";

const StyleGuide = () => {
  return (
    <>
      <div className="">
        <div className="flex flex-wrap gap-11 items-start p-12 mb-12 bg-neutral-20">
          <div>
            <p className="pb-8">Heading H1</p>
            <Headings variant="H1" content="Heading" />
          </div>
          <div>
            <p className="pb-8">Heading H2</p>
            <Headings variant="H2" content="Heading" />
          </div>
        </div>
        <div className="flex flex-wrap gap-11 items-start p-12 mb-12 bg-neutral-20">
          <div>
            <p className="pb-8">Heading H3</p>
            <Headings variant="H3" content="Heading" />
          </div>
          <div>
            <p className="pb-8">Heading H4</p>
            <Headings variant="H4" content="Heading" />
          </div>
        </div>
        <div className="flex flex-wrap gap-11 items-start p-12 mb-12 bg-neutral-20">
          <div>
            <p className="pb-8">Paragraph</p>
            <Paragraph content="Lorem ipsum dolor sit amet consectetur. Egestas posuere quam venenatis integer." />
          </div>
          <div>
            <p className="pb-8">Paragraph with Bottom Padding</p>
            <Paragraph
              content="Lorem ipsum dolor sit amet consectetur. Egestas posuere quam venenatis integer."
              classes="mb-16"
            />
          </div>
        </div>
        <div className="mb-12">
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Primary(Light)</p>
            <div className="flex flex-wrap">
              <div className="p-8">
                <PrimaryButton
                  buttonType="submit"
                  variant="light"
                  name="Button text"
                />
                <br />
                <PrimaryButtonWithIcon
                  buttonType="button"
                  variant="light"
                  name="Button text"
                />
                <br />
                <PrimaryButtonOnlyIcon buttonType="button" variant="light" />
                
              </div>
              <div className="p-8">
                <PrimaryButton
                  buttonType="button"
                  variant="light"
                  name="Button text"
                  loading
                />
                <br />
                <PrimaryButtonWithIcon
                  buttonType="button"
                  variant="light"
                  name="Button text"
                  loading
                />
                <br />
                <PrimaryButtonOnlyIcon
                  buttonType="button"
                  variant="light"
                  loading
                />
              </div>
              <div className="p-8">
                <PrimaryButton
                  buttonType="button"
                  variant="light"
                  name="Button text"
                  disabled
                />
                <br />
                <PrimaryButtonWithIcon
                  buttonType="button"
                  variant="light"
                  name="Button text"
                  disabled
                />
                <br />
                <PrimaryButtonOnlyIcon
                  buttonType="button"
                  variant="light"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Primary(Dark)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <PrimaryButton
                  buttonType="button"
                  variant="dark"
                  name="Button text"
                />
                <br />
                <PrimaryButtonWithIcon
                  buttonType="button"
                  variant="dark"
                  name="Button text"
                />
                <br />
                <PrimaryButtonOnlyIcon buttonType="button" variant="dark" />
              </div>
              <div className="p-8">
                <PrimaryButton
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <PrimaryButtonWithIcon
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <PrimaryButtonOnlyIcon
                  variant="dark"
                  buttonType="button"
                  loading
                />
              </div>
              <div className="p-8">
                <PrimaryButton
                  variant="dark"
                  name="Button text"
                  buttonType="button"
                  disabled
                />
                <br />
                <PrimaryButtonWithIcon
                  variant="dark"
                  name="Button text"
                  buttonType="button"
                  disabled
                />
                <br />
                <PrimaryButtonOnlyIcon
                  variant="dark"
                  buttonType="button"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Outline(Light)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <OutlineButton
                  variant="light"
                  buttonType="button"
                  name="Button text"
                />
                <br />
                <OutlineButtonWithIcon
                  variant="light"
                  buttonType="button"
                  name="Button text"
                />
                <br />
                <OutlineButtonOnlyIcon variant="light" buttonType="button" />
                <br/>
                <OutlineButtonSearchIcon variant="light" buttonType="button" />
              </div>
              <div className="p-8">
                <OutlineButton
                  variant="light"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <OutlineButtonWithIcon
                  variant="light"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <OutlineButtonOnlyIcon
                  variant="light"
                  buttonType="button"
                  loading
                />
              </div>
              <div className="p-8">
                <OutlineButton
                  variant="light"
                  buttonType="button"
                  name="Button text"
                  disabled
                />
                <br />
                <OutlineButtonWithIcon
                  variant="light"
                  buttonType="button"
                  name="Button text"
                  disabled
                />
                <br />
                <OutlineButtonOnlyIcon
                  variant="light"
                  buttonType="button"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Outline(Dark)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <OutlineButton variant="dark" name="Button text" />
                <br />
                <OutlineButtonWithIcon variant="dark" name="Button text" />
                <br />
                <OutlineButtonOnlyIcon variant="dark" />
              </div>
              <div className="p-8">
                <OutlineButton
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <OutlineButtonWithIcon
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  loading
                />
                <br />
                <OutlineButtonOnlyIcon
                  variant="dark"
                  buttonType="button"
                  loading
                />
              </div>
              <div className="p-8">
                <OutlineButton
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  disabled
                />
                <br />
                <OutlineButtonWithIcon
                  variant="dark"
                  buttonType="button"
                  name="Button text"
                  disabled
                />
                <br />
                <OutlineButtonOnlyIcon
                  variant="dark"
                  buttonType="button"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Secondary(Dark)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <SecondaryButton name="Button text" buttonType="button" />
                <br />
                <SecondaryButtonWithIcon
                  name="Button text"
                  buttonType="button"
                />
                <br />
                <SecondaryButtonOnlyIcon buttonType="button" />
              </div>
              <div className="p-8">
                <SecondaryButton
                  name="Button text"
                  loading
                  buttonType="button"
                />
                <br />
                <SecondaryButtonWithIcon
                  name="Button text"
                  loading
                  buttonType="button"
                />
                <br />
                <SecondaryButtonOnlyIcon loading buttonType="button" />
              </div>
              <div className="p-8">
                <SecondaryButton
                  name="Button text"
                  disabled
                  buttonType="button"
                />
                <br />
                <SecondaryButtonWithIcon
                  name="Button text"
                  disabled
                  buttonType="button"
                />
                <br />
                <SecondaryButtonOnlyIcon disabled buttonType="button" />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Text(Light)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <TextButton variant="light" name="Button text" link="/" />
                <br />
                <TextButtonWithIcon
                  variant="light"
                  name="Button text"
                  iconPosition="start"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="light"
                  name="Button text"
                  iconPosition="start"
                  arrowDirection="left"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="light"
                  name="Button text"
                  iconPosition="end"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="light"
                  name="Button text"
                  iconPosition="end"
                  arrowDirection="left"
                  link="/"
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Text(Dark)</p>
            <div className="flex flex-wrap ">
              <div className="p-8">
                <TextButton variant="dark" name="Button text" link="/" />
                <br />
                <TextButtonWithIcon
                  variant="dark"
                  name="Button text"
                  iconPosition="start"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="dark"
                  name="Button text"
                  iconPosition="start"
                  arrowDirection="left"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="dark"
                  name="Button text"
                  iconPosition="end"
                  link="/"
                />
                <br />
                <TextButtonWithIcon
                  variant="dark"
                  name="Button text"
                  iconPosition="end"
                  arrowDirection="left"
                  link="/"
                />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Card Button</p>
            <div className="flex flex-wrap ">
              <div className="p-8 w-100">
                <CardButton name="Button text" link="/" />
                <br />
                <CardButton name="Button text" variant="stacked" link="/" />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Icon list</p>
            <div className="flex flex-wrap ">
              <div className="p-8 w-100">
                <IconList content="Lorem" />
              </div>
            </div>
          </div>
          <div className="p-12 mb-12 bg-neutral-20">
            <p className="pb-8">Tag</p>
            <div className="flex flex-wrap ">
              <div className="p-8 w-100">
                <Tag name="Text" />
              </div>
            </div>
          </div>
          <div>
            <p className="pb-8">Quicklinks</p>

            <QuickLinksBlock />

            <p className="pb-8">Disclaimer</p>

            <DisclaimerBlock
              border={true}
              content="This website contains links to other websites which are not under the control of, and are not maintained by, ESSSuper. ESSSuper is not responsible for the content of those sites. <br/> <br/>
                  ESSSuper provides these links for your convenience only but does not necessarily endorse the material on these sites.The information contained on this website is of a general nature only. It should not be considered as a substitute for reading the relevant ESSSuper handbook or Product Disclosure Statement (PDS) (and associated target market determination) that contains detailed information about ESSSuper products, services and features. Before making a decision about an ESSSuper product, you should consider the appropriateness of the product to your personal objectives, financial situation and needs. It may also be beneficial to seek professional advice from a licensed financial planner or adviser. An ESSSuper PDS is available on our PDS and handbooks webpage or by contacting us."
            />

            <p className="pb-8">Feature Block</p>

            <FeatureBlock variant="image-left" background="white" />
            <FeatureBlock variant="image-left" background="purple" />
            <FeatureBlock variant="image-left" background="orange" />
            <FeatureBlock variant="image-right" background="white" />
            <FeatureBlock variant="image-right" background="purple" />
            <FeatureBlock variant="image-right" background="orange" />
            <FeatureBlock variant="text" background="white" />
            <FeatureBlock variant="text" background="purple" />
            <FeatureBlock variant="text" background="orange" />
            <FeatureBlock variant="appStore" background="white" />
            <FeatureBlock variant="appStore" background="purple" />
            <FeatureBlock variant="appStore" background="orange" />

            <p className="pb-8">Form and Transactions</p>

            <FormsAndTransactions />

            <p className="pb-8">Video</p>

            <Video videoUrl="https://www3.cde.ca.gov/download/rod/big_buck_bunny.mp4" />

            <p className="pb-8">Accordion</p>

            <Accordion />

            <p className="pb-8">Page Banner</p>

            <PageBanner background="white" />
            <PageBanner background="purple" />
            <PageBanner background="orange" />

            <p className="pb-8">Alert</p>

            <Alert background="teal" />
            <Alert background="purple" />
            <Alert background="orange" />

            <p className="pb-8">Banner Notification</p>

            <BannerNotification background="teal" />
            <BannerNotification background="purple" />
            <BannerNotification background="orange" />

            <p className="pb-8">Banner Notification With Close Icon</p>

            <BannerNotification background="teal" closeIcon={true} />
            <BannerNotification background="purple" closeIcon={true} />
            <BannerNotification background="orange" closeIcon={true} />

            <p className="pb-8">Tabs</p>

            <Tabs />

            <p className="pb-8">Text Block</p>

            <TextBlock background="white" />
            <TextBlock background="purple" />
            <TextBlock background="orange" />

            <p className="pb-8">Callouts</p>

            <Callout background="white" />
            <Callout background="lightPurple" />
            <Callout background="orange" textAlignment={false} />
            <Callout background="purple" textAlignment={true} />
            <Callout background="teal" />

            <p className="pb-8">Listings</p>

            <Listing />

            <p className="pb-8">Tab Selector</p>

            <TabSelector />

            <p className="pb-8">Cards</p>

            <Card background="purple" />
            <Card columnLayout="3" border={true} />
            <Card columnLayout="4" background="purple" />
            <Card columnLayout="3" cardBackground="purple" />

            <p className="pb-8">Event Cards</p>

            <EventCards border={true} />
            <EventCardsCarousel />
            <EventDetails />
            <Events />
          </div>
        </div>
      </div>
    </>
  );
};

export default StyleGuide;
