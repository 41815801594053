import * as React from "react"
import { ReactComponent as Spinner } from '../../../../../Assets/spinner.svg'
import { ReactComponent as SearchIcon } from '../../../../../Assets/search-white.svg'
import { ReactComponent as SearchDark } from '../../../../../Assets/search.svg'
import { ReactComponent as CloseIcon } from '../../../../../Assets/close_White.svg'
import { TrackGAEvent } from "../../../../GoogleAnalytics"

interface Props {
    variant: string
    name?: string
    buttonType?: "button" | "submit" | "reset"
    link?: string
    arrowDirection?: string
    loading?: boolean
    disabled?: boolean
    classes?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Outline Button ONly Icon Component
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param buttonType (optional) - Type of the button. Expected values:  "button" | "submit" | "reset", Default: "button"
 * @param link (optional) - Page to be navigate on click of the button.
 * @param arrowDirection (optional) - Direction for the arrow. Expected values: "left" | "right"
 * @param loading (optional) - Loading of the button. Expected values:  true | false
 * @param disabled (optional) - Button to be disabled. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const OutlineButtonSearchIcon = ({ variant, name, buttonType = "button", link, arrowDirection = 'right', loading, disabled, classes, onClick }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, name: string, link: string) => {
    e.preventDefault()
    e.stopPropagation()
    TrackGAEvent('ButtonClick', 'click', name)
    if(link) {
      window.location.href = link
    }
    if(onClick) {
      onClick(e)
    }
  }
  return (
    <>
      {variant === "light" && (<>
        <button  aria-label={name} type={buttonType} className={`group flex justify-center items-center text-base md:text-md rounded-lg 
            px-24 py-16
            ${loading ? 'bg-neutral-30 text-purple-100 border-white border pointer-events-none' : 'bg-transparent text-white border-neutral-50 border' }
            hover:bg-white hover:text-purple-100
            active:bg-neutral-30 active:text-purple-100 active:border-white active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-neutral-30 visited:text-purple-100 visited:border-white visited:border
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={(e: any) => handleClick(e, name, link)}
        >
          <span className="group-hover:hidden">
          {!loading && <>
            {arrowDirection === "left" ? <SearchIcon /> : <SearchIcon />}
          </>}
          </span>
          <span className="hidden group-hover:inline"><SearchDark /></span>
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
      {variant === "dark" && (<>
        <button aria-label={name} type={buttonType} className={`flex justify-center items-center text-base md:text-md rounded-lg 
            px-24 py-16
            ${loading ? 'bg-purple-hoverDark text-white border-purple-hoverDark border pointer-events-none' : 'bg-transparent text-purple-100 border-purple-100 border' }
            hover:bg-purple-100 hover:text-white hover:border-purple-100
            active:bg-purple-hoverDark active:text-white active:border-purple-100 active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-purple-hoverDark visited:text-white visited:border-purple-100 visited:border
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={(e: any) => handleClick(e, name, link)}
        >
          {!loading && <>
            {arrowDirection === "left" ? <CloseIcon /> : <CloseIcon />}
          </>}
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
    </>
  )
}

export default OutlineButtonSearchIcon
