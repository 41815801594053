import * as React from "react"
import Headings from "../../Generic/Heading"
import { ReactComponent as AccordionPlusIcon } from '../../../Assets/accordionPlus.svg'
import { ReactComponent as AccordionMinusIcon } from '../../../Assets/accordionMinus.svg'
import './style.css'
import OutlineButtonWithIcon from "../../Generic/Button/Outline/ButtonWithIcon"
import Paragraph from "../../Generic/Paragraph"
interface Props {
    content?: string
}

/**
 * Accordion Component
 * @param content - Content from the CMS.
 */

const Accordion = ({content}: Props) => {

    const toggleQuickLinks = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.list')
        parentEle.classList.toggle('active')
    }

    return (<>
        <div className="layoutSpacing">
            <div className="w-100 layout">
                <div className="accordion border border-neutral-50 rounded-2xl">
                    <div className="list border-b border-neutral-50">
                        <div className="title cursor-pointer flex justify-between items-cs p-32" onClick={(e) => {toggleQuickLinks(e)}}>
                            <Headings content="How do super investments work?" variant="H4" classes="text-purple-100 pr-12" />
                            <AccordionPlusIcon />
                            <AccordionMinusIcon />
                        </div>
                        <div className={`content w-100`}>
                            <div className={`p-32 pt-0`}>
                                <Paragraph content="Do you know:
    <ul><li>the risks of investing, and what’s right for you?</li>
    <li>how to choose an investment option that’s right for you?</li>
    <li>how daily unit pricing works?</li>
    <li>you can switch investment options to make sure your super stays aligned with your long-term objectives?</li></ul>" classes="mb-16" />
                                <OutlineButtonWithIcon name="Understanding investments" link="/" variant="dark" classes="mb-8" />
                            </div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="title cursor-pointer flex justify-between items-cs p-32 text-purple-100" onClick={(e) => {toggleQuickLinks(e)}}>
                            <Headings content="What are my investment options?" variant="H4" classes="text-purple-100 pr-12" />
                            <AccordionPlusIcon />
                            <AccordionMinusIcon />
                        </div>
                        <div className={`content w-100`}>
                            <div className={`p-32 pt-0`}>
                                <Paragraph content="Do you know:
    <ul><li>the risks of investing, and what’s right for you?</li>
    <li>how to choose an investment option that’s right for you?</li>
    <li>how daily unit pricing works?</li>
    <li>you can switch investment options to make sure your super stays aligned with your long-term objectives?</li></ul>" classes="mb-16" />
                                <OutlineButtonWithIcon name="Understanding investments" link="/" variant="dark" classes="mb-8" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Accordion