import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import './style.css'
import { RenderButton, RenderCardButton } from "../../Generic/Button/RenderButton"
import PageNotFound from "../PageNotFound"
interface Props {
    content?: any
    background?: string
    pageContent?:  any
}

/**
 * TextBlock Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" , Default: "white"
 * @param pageContent (optional) - API data of the page
 */
const TextBlock = ({content, background = 'white', pageContent}: Props) => {
    background = background.toLowerCase()
    const columns = content?.cardLayout === '3' ? 'xl:w-[32%]' : content?.cardLayout === '4' ? 'xl:w-[23%]' : 'xl:w-[49%]'
    return (<>
        {pageContent && 
            <div className="textBlock">
                {background === 'white' && 
                    <div className={`layoutSpacing w-100 mb-40 bg-white`}>
                        <div className="layout">
                            <div className={`flex ${content?.texColtLayout ? 'flex-col xl:flex-row gap-24' : 'flex-col w-100 md:w-75 xl:w-60' }`}>
                                {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100" />}
                                {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                                <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText  || content?.description)} classes="" />
                            </div>
                            {content?.features && <>
                                <div className={`mt-24`}>
                                    {content?.features[0].fields?.ctaButtonVariety.toLowerCase() === 'cardbutton' ?
                                        <RenderCardButton item={content?.features} columns={columns} />
                                        : <>
                                            {content?.features?.map((item, index) => (
                                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                                    <RenderButton item={item} />
                                                </div>
                                            )
                                            )}
                                        </>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                }
                {background === 'purple' && 
                    <div className={`layoutSpacing w-100 mb-40 bg-purple-100`}>
                        <div className="layout">
                            <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                                {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100" />}
                                {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                                <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText)} classes="" />
                            </div>
                                
                            {content?.features && <>
                                <div className={`mt-24`}>
                                    {content?.features[0].fields?.ctaButtonVariety.toLowerCase() === 'cardbutton' ?
                                        <RenderCardButton item={content?.features} />
                                        : <>
                                            {content?.features?.map((item, index) => (
                                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                                    <RenderButton item={item} />
                                                </div>
                                            )
                                            )}
                                        </>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                }
                {background === 'lightpurple' && 
                    <div className={`layoutSpacing w-100 mb-40 bg-purple-10`}>
                        <div className="layout">
                            <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                                {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100" />}
                                {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                                <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText)} classes="" />
                            </div>
                                
                            {content?.features && <>
                                <div className={`mt-24`}>
                                    {content?.features[0].fields?.ctaButtonVariety.toLowerCase() === 'cardbutton' ?
                                        <RenderCardButton item={content?.features} />
                                        : <>
                                            {content?.features?.map((item, index) => (
                                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                                    <RenderButton item={item} />
                                                </div>
                                            )
                                            )}
                                        </>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                }
                {background === 'orange' && 
                    <div className={`layoutSpacing w-100 mb-40 bg-orange-10`}>
                        <div className="layout">
                            <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                                {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100" />}
                                {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                                <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText)} classes="" />
                            </div>
                            {content?.features && <>
                                <div className={`mt-24`}>
                                    {content?.features[0].fields?.ctaButtonVariety.toLowerCase() === 'cardbutton' ?
                                        <RenderCardButton item={content?.features} />
                                        : <>
                                            {content?.features?.map((item, index) => (
                                                <div key={`${item?.fields?.ctaButtonVariety}-${index}`}>
                                                    <RenderButton item={item} />
                                                </div>
                                            )
                                            )}
                                        </>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                }
            </div>
        }
        {!pageContent && 
            <PageNotFound content={content} />
        }
    </>)
}

export default TextBlock