import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import BaseTemplate from './Components/Templates/BaseTemplate'
import { GAInitialize } from './Components/GoogleAnalytics'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

const CustomApp = ({ Component, pageProps }) => (
  <ContentfulLivePreviewProvider locale="en-US">
    <Component {...pageProps} />
  </ContentfulLivePreviewProvider>
)

function App() {
  const RoutesConfig = () => {
    const config = ['/:path', '/:path/:path1', '/:path/:path1/:path2', '/:path/:path1/:path2/:path3'].map((item: any) => {
      const obj = {
        path: item,
        element: <BaseTemplate />
      }
      return obj
    })

    return useRoutes([{
      path:'/',
      element: <BaseTemplate />,
      children: [
        {
          index: true,
          element: <BaseTemplate />
        },
        ...config
      ]
    }])
  }

  return (
    <ContentfulLivePreviewProvider 
    locale="en-US"
    enableLiveUpdates 
    targetOrigin="https://app.contentful.com">
      <GAInitialize />
      <BrowserRouter>
        <RoutesConfig />
      </BrowserRouter>
    </ContentfulLivePreviewProvider>
  )
}

export default App
