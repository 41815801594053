import * as React from "react"

interface Props {
    variant: string
    content: string
    classes?: string
}

/**
 * Heading Component
 * @param variant - Variation of the headings. Expected values: "H1" | "H2" | "H3" | "H4"
 * @param content - Text of the headings.
 * @param classes (optional) - Tailwindcss classes to customize it.
 */

const Headings = ({variant, content, classes}: Props) => {
    return (<>
            {variant === 'H1' && <h1 data-testid="headings" className={`text-h2 md:text-h1 pb-8 ${classes ? classes : ''}`}>{content}</h1>}
            {variant === 'H2' && <h2 data-testid="headings" className={`text-h3 md:text-h2 pb-8 ${classes ? classes : ''}`}>{content}</h2>}
            {variant === 'H3' && <h3 data-testid="headings" className={`text-2xl md:text-h3 pb-8 ${classes ? classes : ''}`}>{content}</h3>}
            {variant === 'H4' && <h4 data-testid="headings" className={`text-lg md:text-h4 pb-8 font-semibold ${classes ? classes : ''}`}>{content}</h4>}
        </>
    )
    
}

export default Headings