import * as React from "react";
import { useState } from "react";
import DesktopMenu from "./DesktopMenu/desktopmenu";
import MobileMenu from "./MobileMenu/mobilemenu";
import MainMenu from "./DesktopMenu/megamenuDesktop";
import Image from "../../Generic/Image"
import "./menu.css"
import HamburgerMenu  from "../../../Assets/menu.png";
import closeWhite from '../../../Assets/close_White.svg'

interface Props {
  content?: any;
}

const Header = ({ content }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <nav>
      {/* header section start */}
      <div className="relative bg-purple-100 layoutSpacing">
        <div className="w-full layout py-24">
          <div className="flex justify-between ">
            <div className="logo">
              <a href="/" className="block h-64">
                <Image
                  imageUrl={
                    content?.fields?.navigationSection?.fields?.logo?.fields
                      ?.file?.url
                  }
                  altText={
                    content?.fields?.navigationSection?.fields?.logo?.fields
                      ?.description
                  }
                  height="100%"
                  width="auto"
                />
              </a>
            </div>
            {/* desktop view */}
            <div className="hidden xl:flex flex-row w-full">
              <DesktopMenu content={content} />
            </div>
            {/* mobile View */}
            <div className="xl:hidden p-24 ">
              <button
                onClick={toggleMenu}
                className="text-neutral-10  focus:outline-none navbutton"
              >
                {!isMenuOpen && (
                  <img
                    src={HamburgerMenu}
                    className="hambargerIcon"
                    alt="Hambarger"
                  />
                )}
                {isMenuOpen && (
                  <img
                    src={closeWhite}
                    className="hambargerIcon"
                    alt="Close Icon"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen && <MobileMenu content={content} />}
      </div>
      {/* main navigation part start */}
      <MainMenu content={content} />
    </nav>
  );
};
export default Header;
