import * as React from "react"

interface Props {
    imageUrl: string
    altText: string
    width?: string
    height?: string
}

/**
 * Image Component
 * @param imageUrl - URL of the image.
 * @param altText - Alternate text of the image.
 * @param width (optional) - Width of the image.
 * @param height (optional) - Height of the image.
 */

const Image = ({imageUrl, altText, width, height}: Props) => {

    return (<>
            <img src={imageUrl} alt={altText} width={`${width ? width : 'auto'}`} height={`${height ? height : 'auto'}`} className="object-cover" />
        </>
    )
    
}

export default Image