import * as React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module-v2'

declare const window: any

const GAInitialize = () => {
    useEffect(() => {
        // Google Tag Manager Initialization
        const tagManagerArgs = {
            gtmId: "GTM-1234" //process.env.REACT_APP_GTM_CONTAINER_ID // your Container ID
        }
        TagManager.initialize(tagManagerArgs)

        // Google Analytics GA4 Initialization
        const TRACKING_ID = "G-1234" //process.env.REACT_APP_GA_MEASUREMENT_ID // your Measurement ID          
        ReactGA.initialize(TRACKING_ID)
    }, [])

    return (<></>)
}

const TrackGAPageView = (title: string) => {

    // GTM PageView
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: window.location.href,
            title
        }
    })

    // GA4 PageView
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.href,
        title 
    })

}

const TrackGAEvent = (category: string, action: string, label: string, nonInteraction?: boolean, value?: number) => {
    // GA4 Custom Event
    ReactGA.event({
        category,
        action,
        label,
        nonInteraction,
        value
    })
}


export { GAInitialize, TrackGAPageView, TrackGAEvent }